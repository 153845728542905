import React from "react";

function Convenience() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="126"
            height="90"
            fill="none"
            viewBox="0 0 126 90"
        >
            <mask
                id="mask0_1_1392"
                style={{ maskType: "alpha" }}
                width="90"
                height="90"
                x="18"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#5692C4" d="M18 0H108V90H18z"></path>
            </mask>
            <g fill="#5692C4" mask="url(#mask0_1_1392)">
                <path d="M82.208 17.844A70.946 70.946 0 0164.002 4.6a1.747 1.747 0 00-2.403 0 70.954 70.954 0 01-18.206 13.243 71.06 71.06 0 01-19.864 6.62c-.87.15-1.479.942-1.401 1.82a74.397 74.397 0 0017.352 41.63A71.393 71.393 0 0062.05 85.28a1.695 1.695 0 001.499 0 71.363 71.363 0 0022.563-17.365 74.406 74.406 0 0017.36-41.63 1.695 1.695 0 00-1.395-1.82 71.044 71.044 0 01-19.87-6.62zm1.342 47.854a67.903 67.903 0 01-20.75 16.16 67.936 67.936 0 01-20.75-16.16 70.768 70.768 0 01-16.385-38.184A74.38 74.38 0 0062.8 8.17a74.362 74.362 0 0017.876 12.692 74.464 74.464 0 0019.26 6.647A70.778 70.778 0 0183.55 65.697z"></path>
                <path d="M78.052 43.08c-.936 0-1.695.76-1.695 1.695A13.557 13.557 0 1162.8 31.218a1.695 1.695 0 000-3.39 16.947 16.947 0 1016.947 16.947 1.695 1.695 0 00-1.695-1.695z"></path>
                <path d="M58.914 40.188a1.695 1.695 0 00-2.397 2.396l6.78 6.78c.317.317.748.496 1.198.496h.094a1.695 1.695 0 001.229-.634L79.375 32.28a1.695 1.695 0 10-2.647-2.118L64.357 45.629l-5.443-5.44z"></path>
            </g>
            <path
                fill="#282828"
                fillOpacity="0.3"
                d="M106.309 56.64a1.038 1.038 0 00.076 1.463l.333.301-.302.333a1.039 1.039 0 00.073 1.463 1.04 1.04 0 001.462-.072l.302-.334.333.301a1.038 1.038 0 001.462-.072 1.037 1.037 0 00-.075-1.463l-.333-.3.301-.334a1.034 1.034 0 00-1.538-1.387l-.301.333-.333-.301a1.034 1.034 0 00-1.46.075v-.006zm2.033.837l.499-.55a.45.45 0 01.666.602l-.498.55a.295.295 0 00.02.415l.55.498c.185.165.2.452.032.634a.447.447 0 01-.634.032l-.551-.498a.297.297 0 00-.211-.075.287.287 0 00-.203.095l-.498.55a.444.444 0 01-.634.032.444.444 0 01-.032-.634l.498-.55a.297.297 0 00-.02-.414l-.55-.499a.446.446 0 01-.032-.634.444.444 0 01.634-.032l.55.498a.297.297 0 00.212.076.242.242 0 00.11-.03.355.355 0 00.089-.066h.003z"
            ></path>
            <path
                fill="#282828"
                fillOpacity="0.1"
                d="M23.076 19.32a.965.965 0 001.029-.895l.029-.417.416.029a.959.959 0 001.026-.895.959.959 0 00-.895-1.025l-.417-.03.029-.416a.96.96 0 00-.895-1.026.965.965 0 00-1.028.895l-.03.417-.416-.029a.965.965 0 00-.794 1.593.95.95 0 00.66.328l.417.029-.029.416a.962.962 0 00.895 1.026h.003zm-.556-1.97l-.69-.046a.415.415 0 01-.387-.446.416.416 0 01.446-.388l.69.046a.27.27 0 00.289-.252l.046-.689a.418.418 0 11.834.058l-.046.69a.272.272 0 00.252.289l.69.046a.415.415 0 01.387.446.415.415 0 01-.446.388l-.689-.046a.272.272 0 00-.29.252l-.046.69a.416.416 0 01-.446.387.415.415 0 01-.388-.446l.046-.689a.273.273 0 00-.066-.197.25.25 0 00-.185-.093z"
            ></path>
            <path
                fill="#282828"
                d="M22.756 73.288a1.022 1.022 0 00-.116-1.44l-.34-.286.287-.34c.177-.208.264-.471.24-.744a1.014 1.014 0 00-.355-.695 1.011 1.011 0 00-.745-.24c-.272.02-.518.148-.695.356l-.287.339-.338-.29a1.012 1.012 0 00-.745-.24 1.022 1.022 0 00-.58 1.796l.34.286-.287.34a1.022 1.022 0 001.555 1.323l.287-.34.339.288a1.03 1.03 0 001.133.13c.115-.058.22-.142.31-.246l-.003.003zm-2.03-.768l-.476.56a.459.459 0 01-.304.156.445.445 0 01-.374-.73l.476-.56a.29.29 0 00-.032-.408l-.56-.475a.445.445 0 01.256-.782.452.452 0 01.324.105l.559.475a.292.292 0 00.408-.035l.475-.56a.452.452 0 01.628-.05.452.452 0 01.05.629l-.475.56a.291.291 0 00.035.408l.559.475a.445.445 0 11-.58.675l-.558-.475a.279.279 0 00-.212-.066.3.3 0 00-.197.101l-.003-.003z"
            ></path>
            <path
                fill="#282828"
                fillOpacity="0.1"
                d="M13.661 62.965a1.288 1.288 0 001.298 1.272l.559-.006.005.56a1.282 1.282 0 001.298 1.27 1.27 1.27 0 00.903-.385c.24-.246.371-.567.368-.912l-.005-.559.558-.006a1.282 1.282 0 001.272-1.297 1.288 1.288 0 00-1.298-1.272l-.558.006-.006-.559a1.29 1.29 0 00-1.298-1.271 1.27 1.27 0 00-.904.385c-.24.246-.37.568-.367.912l.005.56-.559.005a1.285 1.285 0 00-1.268 1.297h-.003zm2.557-.947l-.008-.92a.557.557 0 01.553-.565.56.56 0 01.565.553l.008.92c0 .2.166.363.368.36l.921-.009a.561.561 0 01.406.95.558.558 0 01-.394.168l-.921.009a.356.356 0 00-.255.11.374.374 0 00-.104.258l.008.92a.557.557 0 01-.95.405.557.557 0 01-.168-.393l-.008-.92a.355.355 0 00-.11-.255.374.374 0 00-.258-.105l-.921.009a.56.56 0 01-.012-1.118l.921-.009c.096 0 .189-.04.255-.11a.354.354 0 00.104-.258z"
            ></path>
            <path
                fill="#95C3EA"
                d="M104.464 18.745a.977.977 0 001.355-.263l.238-.35.35.237c.217.145.475.2.733.15a.978.978 0 00.362-1.767l-.351-.237.238-.35a.973.973 0 00-.261-1.356.977.977 0 00-1.355.264l-.238.35-.35-.237a.979.979 0 00-1.506.996.973.973 0 00.411.623l.35.237-.237.35a.975.975 0 00.263 1.356l-.002-.003zm.512-2.01l-.579-.39a.43.43 0 01-.116-.59.42.42 0 01.588-.114l.579.39a.276.276 0 00.383-.075l.391-.579a.421.421 0 01.588-.113.429.429 0 01.115.59l-.391.58a.273.273 0 00.073.382l.579.392c.093.063.156.159.18.272.02.11 0 .223-.067.318a.43.43 0 01-.591.116l-.579-.39a.274.274 0 00-.382.072l-.391.579a.421.421 0 01-.588.113.429.429 0 01-.116-.59l.391-.58a.289.289 0 00.043-.206.264.264 0 00-.116-.177h.006zM11.046 71.785a.633.633 0 00-.87.223l-.141.238-.238-.142a.634.634 0 00-.648 1.089l.237.142-.142.237a.634.634 0 101.09.646l.141-.237.238.142a.633.633 0 00.938-.704.624.624 0 00-.29-.388l-.237-.142.142-.238a.634.634 0 00-.223-.869l.003.003zm-.258 1.324l.39.232a.276.276 0 01.098.377.277.277 0 01-.378.095l-.391-.232a.18.18 0 00-.246.064l-.232.39a.277.277 0 01-.478-.28l.232-.391a.186.186 0 00.02-.136.175.175 0 00-.08-.11l-.392-.232a.276.276 0 01.278-.475l.391.232c.04.023.09.031.136.02a.175.175 0 00.11-.081l.232-.391a.277.277 0 01.478.28l-.232.392a.186.186 0 00-.02.136.189.189 0 00.084.11z"
            ></path>
            <path
                fill="#282828"
                d="M98.307 19.261a1.203 1.203 0 00-1.251-1.15l-.522.023-.023-.52a1.192 1.192 0 00-.388-.835 1.193 1.193 0 00-.863-.316c-.322.015-.617.151-.834.389-.217.237-.33.544-.316.863l.023.52-.521.024c-.322.015-.617.15-.834.388a1.203 1.203 0 00.936 2.013l.52-.023.024.521a1.203 1.203 0 002.085.762c.217-.238.33-.545.316-.866l-.023-.521.521-.024a1.203 1.203 0 001.15-1.25v.002zm-2.367.965l.038.863a.531.531 0 01-.136.376.524.524 0 01-.907-.333l-.037-.863a.338.338 0 00-.353-.324l-.864.037a.524.524 0 01-.046-1.045l.863-.038a.343.343 0 00.324-.353l-.037-.863a.531.531 0 01.136-.377.513.513 0 01.362-.168.532.532 0 01.377.136.526.526 0 01.168.362l.037.864a.343.343 0 00.353.324l.864-.038a.524.524 0 01.046 1.046l-.863.037a.333.333 0 00-.235.11.333.333 0 00-.09.244v.003z"
            ></path>
            <path
                fill="#282828"
                fillOpacity="0.2"
                d="M58.503 15.582a1.13 1.13 0 00-1.53.455l-.234.431-.432-.234a1.107 1.107 0 00-.857-.09 1.11 1.11 0 00-.669.545c-.145.263-.177.57-.09.857.087.29.278.527.545.672l.431.234-.234.432c-.145.263-.177.57-.09.857a1.13 1.13 0 002.074.217l.234-.431.432.234a1.13 1.13 0 001.619-1.312 1.124 1.124 0 00-.545-.672l-.431-.234.234-.432a1.126 1.126 0 00-.455-1.529h-.002zm-.365 2.37l.712.384a.489.489 0 01.197.667.492.492 0 01-.666.197l-.712-.386a.32.32 0 00-.432.128l-.385.712a.492.492 0 01-.863-.469l.385-.712a.325.325 0 00.026-.244.31.31 0 00-.154-.188l-.712-.385a.488.488 0 01-.197-.666.488.488 0 01.666-.197l.713.385c.075.04.162.05.243.026a.303.303 0 00.188-.154l.385-.712a.492.492 0 01.864.47l-.386.712a.324.324 0 00.032.353.314.314 0 00.096.078z"
            ></path>
            <path
                fill="#95C3EA"
                d="M5.17 43.264a1.068 1.068 0 00-.93-1.19l-.46-.058.057-.46a1.07 1.07 0 10-2.12-.261l-.058.46-.46-.058a1.07 1.07 0 00-1.19.93 1.068 1.068 0 00.93 1.19l.46.058-.058.46a1.069 1.069 0 002.12.262l.058-.46.46.057a1.068 1.068 0 001.19-.93zm-2.213.501l-.093.762a.468.468 0 01-.174.31.47.47 0 01-.341.095.468.468 0 01-.406-.518l.093-.762a.303.303 0 00-.264-.336l-.762-.092a.468.468 0 01-.231-.829.47.47 0 01.342-.095l.761.093a.31.31 0 00.223-.061.294.294 0 00.113-.2l.093-.762a.475.475 0 01.174-.31.468.468 0 01.652.078.47.47 0 01.095.342l-.093.762a.31.31 0 00.061.223c.05.064.122.104.2.113l.762.093a.468.468 0 01.231.828.47.47 0 01-.341.095l-.762-.092a.31.31 0 00-.301.15.31.31 0 00-.038.11l.006.003z"
            ></path>
            <path
                fill="#282828"
                d="M125.592 31.642a1.117 1.117 0 00-.047-1.575l-.353-.334.333-.353a1.115 1.115 0 10-1.622-1.53l-.333.354-.353-.333a1.112 1.112 0 00-1.576.047c-.42.446-.4 1.152.047 1.575l.353.333-.333.354a1.116 1.116 0 001.622 1.529l.333-.354.353.334a1.114 1.114 0 001.576-.047zm-2.17-.947l-.547.582a.485.485 0 01-.686.018.488.488 0 01-.021-.687l.548-.582a.316.316 0 00-.015-.446l-.582-.547a.487.487 0 01-.02-.687.483.483 0 01.686-.017l.582.547a.325.325 0 00.226.087.305.305 0 00.22-.098l.548-.582a.483.483 0 01.686-.018.488.488 0 01.018.686l-.548.583a.324.324 0 00-.087.226c0 .084.038.162.099.22l.582.547c.194.182.203.49.02.686a.484.484 0 01-.686.017l-.582-.547a.325.325 0 00-.226-.086.331.331 0 00-.22.099h.005z"
            ></path>
        </svg>
    );
}

export default Convenience;
