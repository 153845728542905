import React, { ReactElement } from "react";

import UnexpectedRepairs from "../icons/unexpectedRepairs";
import PeaceOfMind from "../icons/peaceOfMind";
import Convenience from "../icons/convenience";
import IncreasesValue from "../icons/increasesValue";
import SaveMoney from "../icons/saveMoney";
import EasyClaimsProcess from "../icons/easyClaimsProcess";
import styles from "./whyUs.module.scss";
import FeatureList from "@components/shared/featureList";

const whyUsList = [
    {
        title: "Protection Against Unexpected Repairs",
        description:
            "With a home warranty's dependable coverage, say goodbye to unforeseen repair costs.",

        icon: <UnexpectedRepairs />,
    },
    {
        title: "Experience Peace of Mind",
        description:
            "Enjoy a sense of peace and security knowing that a home warranty is protecting your house and appliances.",

        icon: <PeaceOfMind />,
    },
    {
        title: "Effortless Claims Process",
        description:
            "You can initiate the repair process with just a single phone call and have your home warranty provider handle the rest.",
        icon: <EasyClaimsProcess />,
    },
    {
        title: "Convenience at Its Best",
        description:
            "Maximize convenience by seamlessly integrating your home warranty with your existing homeowners' insurance.",
        icon: <Convenience />,
    },
    {
        title: "Save Money on Repairs",
        description:
            "Keep your hard-earned money in your pocket with a home warranty that covers repairs and replacement costs.",
        icon: <SaveMoney />,
    },
    {
        title: "Boost Your Homes' Value",
        description:
            "Elevate the value of your home by having the added advantage of a comprehensive home warranty.",
        icon: <IncreasesValue />,
    },
];

export default function WhyUs(): ReactElement {
    return (
        <FeatureList
            featureList={whyUsList}
            mainTitle="The Benefits of a Home Warranty"
            classes={{
                mainSectionClasses:
                    "max-w-7xl mx-auto px-5 mt-10 mb-16 lg:mt-[170px] mt-[120px] ",
                mainTitleClasses: `text-2xl font-bold lg:text-3xl mb-2 lg:mb-3 mx-auto ${styles["width"]} `,
                mainDescriptionClasses: `text-base font-light mb-0 mx-auto  ${styles["width"]}`,
                innerSectionClasses:
                    "  w-full md:w-7/12 lg:w-full mt-10 md:mx-auto  lg:flex lg:flex-wrap lg:justify-between lg:mt-14 ",
                oneBoxClasses: `  flex flex-col items-center justify-center text-center mb-10 lg:mb-9 lg:w-1/3 ${styles["list-item"]}`,
                iconClasses:
                    "mb-6  children:w-[93%] children:h-[93%]   flex justify-center items-center",
                titleClasses:
                    "text-base lg:text-lg font-normal mb-1 lg:mb-2 leading-6  ",
                descriptionClasses: "text-sm lg:text-base ",
            }}
            colors={{
                mainTitleColor: "#5692C4",

                titleColor: "#5692C4",
            }}
        />
    );
}
