import React from "react";

function PeaceOfMind() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="126"
            height="90"
            fill="none"
            viewBox="0 0 126 90"
        >
            <mask
                id="mask0_1_1154"
                style={{ maskType: "alpha" }}
                width="91"
                height="90"
                x="17"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#5692C4" d="M17.518 0H107.518V90H17.518z"></path>
            </mask>
            <g fill="#5692C4" mask="url(#mask0_1_1154)">
                <path d="M65.052 77.94h-25.29a5.326 5.326 0 01-5.325-5.324v-6.655a1.331 1.331 0 012.662 0v6.655a2.663 2.663 0 002.662 2.662h25.291a6.155 6.155 0 003.604-1.492l4.773-4.773a1.331 1.331 0 011.882 1.882l-4.773 4.774a8.784 8.784 0 01-5.486 2.272z"></path>
                <path d="M89.013 87.258H51.74a1.331 1.331 0 01-1.33-1.33v-5.325a1.331 1.331 0 012.662 0v3.993h34.44l-3.18-26.642a1.33 1.33 0 01.484-1.19A29.286 29.286 0 1037.1 34.015v3.993a1.32 1.32 0 01-.176.666l-7.706 13.444a1.33 1.33 0 001.226 1.864h3.994a1.331 1.331 0 011.33 1.33v6.656a1.331 1.331 0 01-2.662 0v-5.324h-2.662a3.994 3.994 0 01-3.572-5.779l7.566-13.21v-3.64A31.945 31.945 0 0158.95 2.938a31.95 31.95 0 0135.924 16.618 31.944 31.944 0 01-7.813 38.803l3.273 27.412a1.331 1.331 0 01-1.322 1.489v-.002z"></path>
                <path d="M50.525 47.44a1.33 1.33 0 01-1.065-.526 21.093 21.093 0 01-4.375-12.9 21.322 21.322 0 0121.298-21.298 21.091 21.091 0 0112.902 4.38 1.33 1.33 0 11-1.617 2.117 18.447 18.447 0 00-11.285-3.835 18.657 18.657 0 00-18.636 18.636A18.453 18.453 0 0051.581 45.3a1.33 1.33 0 01-1.057 2.14zM66.384 55.312a21.087 21.087 0 01-12.902-4.381 1.33 1.33 0 111.616-2.116 18.45 18.45 0 0011.286 3.835A18.656 18.656 0 0085.02 34.014a18.453 18.453 0 00-3.833-11.286 1.331 1.331 0 112.116-1.615 21.096 21.096 0 014.38 12.901 21.322 21.322 0 01-21.298 21.298z"></path>
                <path d="M80.854 36.022a10.41 10.41 0 00-3.93-3.703c.53-1.76.582-3.628.153-5.415a1.33 1.33 0 00-.932-.922 10.093 10.093 0 00-3.207-.259 1.335 1.335 0 00.192 2.662 8.114 8.114 0 011.519.034c.3 1.975-.32 3.98-1.683 5.44a5.882 5.882 0 01-3.255 1.634 8.561 8.561 0 001.997-5.473 9.514 9.514 0 00-4.672-7.816 1.332 1.332 0 00-1.305 0 10.344 10.344 0 00-3.717 3.936 10.356 10.356 0 00-5.4-.154 1.33 1.33 0 00-.923.92 9.504 9.504 0 002.223 8.83 8.552 8.552 0 005.275 2.45 5.87 5.87 0 01-3.46 1.152 6.524 6.524 0 01-5.04-2.662c.132-.17.298-.362.488-.564a1.33 1.33 0 00-1.93-1.832 9.675 9.675 0 00-1.331 1.73 1.334 1.334 0 000 1.307A9.515 9.515 0 0059.727 42a8.953 8.953 0 006.655-3.117A8.953 8.953 0 0073.04 42a9.514 9.514 0 007.816-4.672 1.33 1.33 0 00-.001-1.306zm-14.47-11.044a6.523 6.523 0 012.662 5.042 6.523 6.523 0 01-2.662 5.043 6.511 6.511 0 01-2.662-5.043c.066-2 1.047-3.86 2.662-5.042zm-6.588 8.876a6.514 6.514 0 01-1.685-5.439 7.747 7.747 0 013.061.266 8.28 8.28 0 00-.113 1.34c.006 2 .713 3.935 1.997 5.47a5.875 5.875 0 01-3.26-1.637zm13.243 5.484a5.869 5.869 0 01-3.46-1.152 8.54 8.54 0 005.272-2.451c.316-.32.606-.665.867-1.032a7.702 7.702 0 012.357 1.98 6.521 6.521 0 01-5.036 2.655z"></path>
            </g>
            <path
                fill="#282828"
                fillOpacity="0.3"
                d="M106.309 56.64a1.038 1.038 0 00.076 1.463l.333.301-.302.333a1.039 1.039 0 00.073 1.463 1.04 1.04 0 001.462-.072l.302-.334.333.301a1.038 1.038 0 001.462-.072 1.037 1.037 0 00-.075-1.463l-.333-.3.301-.334a1.034 1.034 0 00-1.538-1.387l-.301.333-.333-.301a1.034 1.034 0 00-1.46.075v-.006zm2.033.837l.499-.55a.45.45 0 01.666.602l-.498.55a.295.295 0 00.02.415l.55.498c.185.165.2.452.032.634a.447.447 0 01-.634.032l-.551-.498a.297.297 0 00-.211-.075.287.287 0 00-.203.095l-.498.55a.444.444 0 01-.634.032.444.444 0 01-.032-.634l.498-.55a.297.297 0 00-.02-.414l-.55-.499a.446.446 0 01-.032-.634.444.444 0 01.634-.032l.55.498a.297.297 0 00.212.076.242.242 0 00.11-.03.38.38 0 00.09-.066h.002z"
            ></path>
            <path
                fill="#282828"
                fillOpacity="0.1"
                d="M23.076 19.32a.965.965 0 001.029-.895l.029-.417.416.029a.959.959 0 001.026-.895.959.959 0 00-.895-1.025l-.417-.03.029-.416a.96.96 0 00-.895-1.026.965.965 0 00-1.028.895l-.03.417-.416-.029a.965.965 0 00-.794 1.593.95.95 0 00.66.328l.418.029-.03.416a.962.962 0 00.895 1.026h.003zm-.556-1.97l-.689-.046a.415.415 0 01-.388-.446.416.416 0 01.446-.388l.69.046a.27.27 0 00.289-.252l.046-.689a.418.418 0 11.834.058l-.046.69a.272.272 0 00.252.289l.69.046a.415.415 0 01.387.446.415.415 0 01-.446.388l-.689-.046a.273.273 0 00-.29.252l-.046.69a.416.416 0 01-.446.387.415.415 0 01-.388-.446l.046-.689a.272.272 0 00-.066-.197.25.25 0 00-.185-.093z"
            ></path>
            <path
                fill="#282828"
                d="M22.756 73.288a1.022 1.022 0 00-.116-1.44l-.34-.286.287-.34c.177-.208.264-.471.24-.744a1.014 1.014 0 00-.355-.695 1.012 1.012 0 00-.745-.24c-.272.02-.518.148-.695.356l-.287.339-.338-.29a1.012 1.012 0 00-.745-.24 1.022 1.022 0 00-.58 1.796l.34.286-.287.34a1.022 1.022 0 00.86 1.679c.273-.023.519-.148.695-.356l.287-.34.34.288a1.03 1.03 0 001.131.13c.116-.058.22-.142.31-.246l-.002.003zm-2.03-.768l-.476.56a.459.459 0 01-.304.156.445.445 0 01-.374-.73l.476-.56a.29.29 0 00-.032-.408l-.56-.475a.445.445 0 01.256-.782.452.452 0 01.324.105l.559.475a.292.292 0 00.408-.035l.475-.56a.452.452 0 01.628-.05.452.452 0 01.05.629l-.475.56a.291.291 0 00.035.408l.559.475a.445.445 0 11-.58.675l-.558-.475a.278.278 0 00-.212-.066.289.289 0 00-.197.101l-.003-.003z"
            ></path>
            <path
                fill="#282828"
                fillOpacity="0.1"
                d="M13.661 62.965a1.288 1.288 0 001.298 1.272l.559-.006.005.56a1.282 1.282 0 001.298 1.27 1.27 1.27 0 00.903-.385c.24-.246.371-.567.368-.912l-.005-.559.558-.006a1.281 1.281 0 001.272-1.297 1.288 1.288 0 00-1.298-1.272l-.558.006-.006-.559a1.29 1.29 0 00-1.298-1.271 1.27 1.27 0 00-.904.385c-.24.246-.37.568-.367.912l.005.56-.559.005a1.284 1.284 0 00-1.268 1.297h-.003zm2.557-.947l-.008-.92a.557.557 0 01.553-.565.56.56 0 01.565.553l.008.92c0 .2.166.363.368.36l.921-.009a.561.561 0 01.406.95.557.557 0 01-.394.168l-.921.009a.356.356 0 00-.255.11.374.374 0 00-.104.258l.008.92a.557.557 0 01-.95.405.557.557 0 01-.168-.393l-.008-.92a.355.355 0 00-.11-.255.374.374 0 00-.258-.105l-.921.009a.56.56 0 01-.012-1.118l.921-.009c.096 0 .189-.04.255-.11a.354.354 0 00.104-.258z"
            ></path>
            <path
                fill="#95C3EA"
                d="M104.464 18.745a.977.977 0 001.355-.263l.238-.35.35.237c.217.145.475.2.733.15a.978.978 0 00.362-1.767l-.351-.237.238-.35a.973.973 0 00-.261-1.356.977.977 0 00-1.355.264l-.238.35-.35-.237a.979.979 0 00-1.506.996.973.973 0 00.411.623l.35.237-.237.35a.975.975 0 00.263 1.356l-.002-.003zm.512-2.01l-.579-.39a.43.43 0 01-.116-.59.42.42 0 01.588-.114l.579.39a.276.276 0 00.383-.075l.391-.579a.421.421 0 01.588-.113.429.429 0 01.115.59l-.391.58a.273.273 0 00.073.382l.579.392c.093.063.156.159.18.272.02.11 0 .223-.067.318a.43.43 0 01-.591.116l-.579-.39a.274.274 0 00-.382.072l-.391.579a.421.421 0 01-.588.113.429.429 0 01-.116-.59l.391-.58a.289.289 0 00.043-.206.264.264 0 00-.116-.177h.006zM11.046 71.785a.633.633 0 00-.87.223l-.141.238-.238-.142a.634.634 0 00-.648 1.089l.237.142-.142.237a.634.634 0 101.09.646l.141-.237.238.142a.633.633 0 00.938-.704.624.624 0 00-.29-.388l-.237-.142.142-.238a.634.634 0 00-.223-.869l.003.003zm-.258 1.324l.39.232a.276.276 0 01.098.377.277.277 0 01-.378.095l-.391-.232a.18.18 0 00-.246.064l-.232.39a.277.277 0 01-.478-.28l.232-.391a.186.186 0 00.02-.136.175.175 0 00-.08-.11l-.392-.232a.276.276 0 01.278-.475l.391.232c.04.023.09.031.136.02a.175.175 0 00.11-.081l.232-.391a.277.277 0 01.478.28l-.232.392a.186.186 0 00-.02.136.189.189 0 00.084.11z"
            ></path>
            <path
                fill="#282828"
                d="M98.307 19.261a1.203 1.203 0 00-1.251-1.15l-.522.023-.023-.52a1.192 1.192 0 00-.388-.835 1.193 1.193 0 00-.863-.316c-.322.015-.617.15-.834.388-.217.238-.33.545-.316.864l.023.52-.521.024c-.322.014-.617.15-.834.388a1.203 1.203 0 00.936 2.013l.52-.023.024.521a1.203 1.203 0 002.085.762c.217-.238.33-.545.316-.866l-.023-.521.521-.024a1.204 1.204 0 001.15-1.25v.002zm-2.367.965l.038.863a.531.531 0 01-.136.376.524.524 0 01-.907-.333l-.037-.863a.338.338 0 00-.353-.324l-.864.037a.524.524 0 01-.046-1.045l.863-.038a.343.343 0 00.324-.353l-.037-.863a.531.531 0 01.136-.377.514.514 0 01.362-.168.531.531 0 01.377.136.525.525 0 01.168.362l.037.863a.343.343 0 00.353.324l.864-.037a.524.524 0 01.046 1.046l-.863.037a.333.333 0 00-.235.11.333.333 0 00-.09.244v.003z"
            ></path>
            <path
                fill="#282828"
                fillOpacity="0.2"
                d="M58.503 15.582a1.13 1.13 0 00-1.53.455l-.234.431-.432-.234a1.107 1.107 0 00-.857-.09 1.11 1.11 0 00-.669.544c-.145.264-.177.571-.09.858.087.29.278.527.545.672l.431.234-.234.432c-.145.263-.177.57-.09.857a1.13 1.13 0 002.074.217l.234-.431.432.234a1.13 1.13 0 001.619-1.312 1.124 1.124 0 00-.545-.672l-.431-.234.234-.432a1.126 1.126 0 00-.455-1.529h-.002zm-.365 2.37l.712.384a.488.488 0 01.197.667.492.492 0 01-.666.197l-.712-.386a.32.32 0 00-.432.128l-.385.712a.492.492 0 01-.863-.469l.385-.712a.324.324 0 00.026-.244.31.31 0 00-.154-.188l-.712-.385a.488.488 0 01-.197-.666.488.488 0 01.666-.197l.713.385c.075.04.162.05.243.026a.302.302 0 00.188-.154l.385-.712a.492.492 0 01.864.47l-.386.712a.324.324 0 00.032.353.317.317 0 00.096.078z"
            ></path>
            <path
                fill="#95C3EA"
                d="M5.17 43.264a1.068 1.068 0 00-.93-1.19l-.46-.058.057-.46a1.07 1.07 0 10-2.12-.261l-.058.46-.46-.058a1.07 1.07 0 00-1.19.93 1.068 1.068 0 00.93 1.19l.46.058-.058.46a1.069 1.069 0 002.12.262l.058-.46.46.057a1.068 1.068 0 001.19-.93zm-2.213.501l-.093.762a.468.468 0 01-.174.31.47.47 0 01-.341.095.468.468 0 01-.406-.518l.093-.762a.303.303 0 00-.264-.336l-.762-.092a.468.468 0 01-.231-.829.47.47 0 01.342-.095l.761.093a.31.31 0 00.223-.061.294.294 0 00.113-.2l.093-.762a.475.475 0 01.174-.31.468.468 0 01.652.078.47.47 0 01.095.342l-.093.762a.31.31 0 00.061.223c.05.064.122.104.2.113l.762.093a.468.468 0 01.231.828.47.47 0 01-.341.095l-.762-.092a.31.31 0 00-.301.15.31.31 0 00-.038.11l.006.003z"
            ></path>
            <path
                fill="#282828"
                d="M125.592 31.642a1.117 1.117 0 00-.047-1.575l-.353-.334.333-.353a1.115 1.115 0 10-1.622-1.53l-.333.354-.353-.333a1.112 1.112 0 00-1.576.047c-.42.446-.4 1.152.047 1.575l.353.333-.333.354a1.116 1.116 0 001.622 1.529l.333-.354.353.334a1.114 1.114 0 001.576-.047zm-2.17-.947l-.547.582a.485.485 0 01-.686.018.488.488 0 01-.021-.687l.548-.582a.316.316 0 00-.015-.446l-.582-.547a.487.487 0 01-.02-.687.483.483 0 01.686-.017l.582.547a.325.325 0 00.226.087.305.305 0 00.22-.098l.548-.582a.483.483 0 01.686-.018.488.488 0 01.018.686l-.548.583a.324.324 0 00-.087.226c0 .084.038.162.099.22l.582.547c.194.182.203.49.02.686a.484.484 0 01-.686.017l-.582-.547a.325.325 0 00-.226-.086.331.331 0 00-.22.099h.005z"
            ></path>
        </svg>
    );
}

export default PeaceOfMind;
