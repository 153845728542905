import React from "react";

function SideImage() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 398 276"
        >
            <g clipPath="url(#clip0_1_969)">
                <path
                    fill="#fff"
                    d="M148.807 135.147H38.745v137.96h110.062v-137.96z"
                ></path>
                <path
                    fill="url(#paint0_linear_1_969)"
                    d="M140.135 115.632H38.745v19.515h110.062v-10.829l-8.672-8.686z"
                ></path>
                <path
                    fill="#fff"
                    d="M140.135 124.318v-8.686l8.672 8.686h-8.672zM88.136 123.803H54.948v4.907h33.188v-4.907zM132.603 123.803H99.415v4.907h33.188v-4.907z"
                ></path>
                <path
                    fill="url(#paint1_linear_1_969)"
                    d="M130.457 147.784H56.129v5.186h74.328v-5.186z"
                ></path>
                <path
                    fill="url(#paint2_linear_1_969)"
                    d="M89.796 160.437h-41.91v2.593h41.91v-2.593z"
                ></path>
                <path
                    fill="url(#paint3_linear_1_969)"
                    d="M137.869 160.437h-41.91v2.593h41.91v-2.593z"
                ></path>
                <path
                    fill="url(#paint4_linear_1_969)"
                    d="M76.76 167.937H47.886v2.593H76.76v-2.593z"
                ></path>
                <path
                    fill="url(#paint5_linear_1_969)"
                    d="M112.168 167.937H83.293v2.593h28.875v-2.593z"
                ></path>
                <path
                    fill="url(#paint6_linear_1_969)"
                    d="M138.7 167.937h-19.999v2.593H138.7v-2.593z"
                ></path>
                <path
                    fill="url(#paint7_linear_1_969)"
                    d="M89.796 176.271h-41.91v2.593h41.91v-2.593z"
                ></path>
                <path
                    fill="url(#paint8_linear_1_969)"
                    d="M137.869 176.271h-41.91v2.593h41.91v-2.593z"
                ></path>
                <path
                    fill="url(#paint9_linear_1_969)"
                    d="M76.76 183.771H47.886v2.593H76.76v-2.593z"
                ></path>
                <path
                    fill="url(#paint10_linear_1_969)"
                    d="M94.695 183.771H83.293v2.593h11.402v-2.593z"
                ></path>
                <path
                    fill="url(#paint11_linear_1_969)"
                    d="M112.399 183.771h-11.402v2.593h11.402v-2.593z"
                ></path>
                <path
                    fill="url(#paint12_linear_1_969)"
                    d="M138.7 183.771h-19.999v2.593H138.7v-2.593z"
                ></path>
                <path
                    fill="url(#paint13_linear_1_969)"
                    d="M130.457 197.411H56.129v5.186h74.328v-5.186z"
                ></path>
                <path
                    fill="url(#paint14_linear_1_969)"
                    d="M89.796 210.066h-41.91v2.593h41.91v-2.593z"
                ></path>
                <path
                    fill="url(#paint15_linear_1_969)"
                    d="M137.869 210.066h-41.91v2.593h41.91v-2.593z"
                ></path>
                <path
                    fill="url(#paint16_linear_1_969)"
                    d="M76.76 217.566H47.886v2.593H76.76v-2.593z"
                ></path>
                <path
                    fill="url(#paint17_linear_1_969)"
                    d="M112.168 217.566H83.293v2.593h28.875v-2.593z"
                ></path>
                <path
                    fill="url(#paint18_linear_1_969)"
                    d="M138.7 217.566h-19.999v2.593H138.7v-2.593z"
                ></path>
                <path
                    fill="url(#paint19_linear_1_969)"
                    d="M89.796 225.898h-41.91v2.593h41.91v-2.593z"
                ></path>
                <path
                    fill="url(#paint20_linear_1_969)"
                    d="M137.869 225.898h-41.91v2.593h41.91v-2.593z"
                ></path>
                <path
                    fill="url(#paint21_linear_1_969)"
                    d="M76.76 233.398H47.886v2.593H76.76v-2.593z"
                ></path>
                <path
                    fill="url(#paint22_linear_1_969)"
                    d="M94.695 233.398H83.293v2.593h11.402v-2.593z"
                ></path>
                <path
                    fill="url(#paint23_linear_1_969)"
                    d="M112.399 233.398h-11.402v2.593h11.402v-2.593z"
                ></path>
                <path
                    fill="url(#paint24_linear_1_969)"
                    d="M138.7 233.398h-19.999v2.593H138.7v-2.593z"
                ></path>
                <path
                    fill="url(#paint25_linear_1_969)"
                    d="M127.818 240.212c-7.166 0-12.977 5.82-12.977 12.997s5.811 12.997 12.977 12.997 12.976-5.82 12.976-12.997-5.81-12.997-12.976-12.997zm0 22.537c-5.261 0-9.526-4.271-9.526-9.54 0-5.269 4.265-9.54 9.526-9.54 5.26 0 9.525 4.271 9.525 9.54 0 5.269-4.265 9.54-9.525 9.54z"
                ></path>
                <path
                    fill="url(#paint26_linear_1_969)"
                    d="M71.298 252.317H47.886v2.44h23.412v-2.44z"
                ></path>
                <path
                    fill="url(#paint27_linear_1_969)"
                    d="M101.501 252.317H78.09v2.44H101.5v-2.44z"
                ></path>
                <path
                    fill="url(#paint28_linear_1_969)"
                    d="M378.604 116.588l-.07-1.489-1.469-.279c-9.78-1.85-21.943-4.956-25.441-5.86-8.858-2.308-25.345-6.839-36.184-11.32l-.73-.29-.716.29c-10.839 4.481-27.332 9.012-36.192 11.32-3.503.904-15.658 4.009-25.45 5.87l-1.457.269-.084 1.494c-.221 4.423-.697 9.198-1.196 14.252-3.544 35.68-8.391 84.558 64.067 131.392l1.028.657 1.027-.657c72.459-46.829 67.612-95.704 64.068-131.392-.503-5.054-.975-9.829-1.201-14.255v-.002z"
                ></path>
                <path
                    fill="url(#paint29_linear_1_969)"
                    d="M314.711 97.858c-10.84 4.449-27.139 8.928-35.932 11.217-3.502.903-15.659 4.009-25.449 5.872l-1.458.267-.083 1.493c-.222 4.423-.695 9.198-1.198 14.25-3.544 35.684-8.388 84.559 64.068 131.393l.052.031V97.857v.001z"
                ></path>
                <path
                    fill="url(#paint30_linear_1_969)"
                    d="M387.593 107.565l.044-.758h-.764c-7.729 0-56.65-11.866-71.847-19.52l-.314-.15-.334.15c-15.175 7.654-64.098 19.52-71.838 19.52h-.765l.051.758c.421 6.303-.313 13.727-1.161 22.289-3.658 36.779-9.159 92.36 73.667 143.02l.378.233.371-.233c82.823-50.66 77.322-106.241 73.677-143.02-.86-8.562-1.593-15.986-1.169-22.289h.004zm-71.866 154.138l-1.015.653-1.024-.653c-71.993-46.535-67.181-95.095-63.657-130.553.495-5.018.969-9.764 1.19-14.158l.083-1.483 1.45-.267c9.729-1.851 21.805-4.936 25.286-5.834 8.803-2.293 25.19-6.796 35.959-11.248l.716-.289.724.289c10.767 4.454 27.148 8.955 35.949 11.248 3.477.898 15.562 3.983 25.281 5.823l1.458.278.072 1.477c.223 4.398.693 9.146 1.193 14.164 3.521 35.462 8.339 84.022-63.66 130.553h-.005z"
                ></path>
                <path
                    fill="#fff"
                    d="M347.842 149.236l-41.139 41.207-17.72-17.741a4.102 4.102 0 00-5.806 0 4.116 4.116 0 000 5.811l20.574 20.609a4.1 4.1 0 002.953 1.198 4.1 4.1 0 002.945-1.201l43.999-44.068a4.118 4.118 0 000-5.813 4.104 4.104 0 00-5.806 0v-.002z"
                ></path>
                <path
                    fill="url(#paint31_linear_1_969)"
                    d="M68.455 272.2c.45.304.93.563 1.43.774.362.107.646.238 1.006.252h29.353c.244-.476.4-.992.436-1.535.12-1.872-1.313-3.662-3.121-4.154s-3.829.217-5.11 1.586c.638-.934.153-2.417-.91-2.794-.881-.311-1.885.098-2.538.765-.31.318-.558.684-.77 1.074.448-1.159.712-2.391.76-3.635.023-.581-.019-1.22-.403-1.655-.618-.701-1.762-.519-2.6-.105-1.743.861-3.053 2.513-3.586 4.379a4.347 4.347 0 00-.184-1.424c-.353-1.103-1.48-2.052-2.605-1.783-.892.214-1.484 1.117-1.623 2.024-.138.908.085 1.827.306 2.719-.416-1.268-.84-2.548-1.533-3.688-.693-1.14-1.69-2.145-2.946-2.588-1.256-.444-2.788-.233-3.713.728-.764.792-1.022 1.985-.837 3.069.184 1.084.77 2.068 1.494 2.896-.558-.311-1.13-.628-1.76-.73-.63-.103-1.342.05-1.747.544-.418.507-.416 1.27-.136 1.864.281.595.794 1.047 1.337 1.415v.002z"
                ></path>
                <path
                    fill="url(#paint32_linear_1_969)"
                    d="M77.593 263.893s-1.609-.186-.898-3.017c0 0 2.272 1.821.898 3.017z"
                ></path>
                <path
                    fill="url(#paint33_linear_1_969)"
                    d="M82.7 263.984s-1.081-.833.657-2.565c0 0 .85 2.298-.657 2.565z"
                ></path>
                <path
                    fill="url(#paint34_linear_1_969)"
                    d="M93.693 267.138s-.492-.999 1.402-1.64c0 0-.203 1.987-1.402 1.64z"
                ></path>
                <path
                    fill="url(#paint35_linear_1_969)"
                    d="M91.75 266.406a1.373 1.373 0 00-.23-.104c-.004 0-.008-.002-.013-.004a30.904 30.904 0 00-1.824 2.981c-.058-.821-.407-2.296-.423-2.368l-.151.036-.103.024.015.061c.076.329.478 2.171.423 2.707l.065-.127c-.625 1.201-1.183 2.493-1.409 3.588h.264c.128-.6.36-1.261.644-1.933l.013.015c.03-.024 2.454-2.057 3.68-2.914.014-.107.017-.215.012-.324-.926.621-2.704 2.078-3.484 2.725.819-1.777 1.942-3.538 2.52-4.361v-.002z"
                ></path>
                <path
                    fill="url(#paint36_linear_1_969)"
                    d="M88.96 264.727c-.785.155-1.872.716-2.38.996a54.687 54.687 0 011.369-3.264 1.749 1.749 0 00-.262-.059 51.38 51.38 0 00-2.133 5.416c-.586-1.095-1.164-3.463-1.261-3.874a7.187 7.187 0 00-.215.22c.122 1.157 1.344 4.037 1.344 4.037-.55 1.734-.936 3.419-1.016 5.003h.262c.116-2.174.944-4.881 1.77-7.096.066-.037 1.546-.895 2.507-1.106.008-.09.013-.18.018-.269l-.002-.004z"
                ></path>
                <path
                    fill="url(#paint37_linear_1_969)"
                    d="M96.623 270.428a53.573 53.573 0 012.618-1.98 3.487 3.487 0 00-.206-.174c-.763.539-2.863 2.064-4.449 3.577-.068-.637-.259-3.01.483-4.325a5.395 5.395 0 00-.346.104c-.747 1.631-.397 4.384-.378 4.516l.04-.101c-.403.394-.762.787-1.05 1.155h.33c.667-.81 1.686-1.727 2.686-2.55v.011c.974.104 3.305.473 4.303 1.076.001-.025.006-.048.008-.07.005-.075.003-.149.003-.222-1.124-.613-3.272-.921-4.042-1.015v-.002z"
                ></path>
                <path
                    fill="url(#paint38_linear_1_969)"
                    d="M81.845 265.128l-1.409 3.371c-.325-2.06-.675-3.925-.802-4.586-.013.003-.026.003-.04.006a1.467 1.467 0 00-.21.067c.19 1.001.844 4.508 1.205 7.423l-2.657-4.752c.005.21.026.422.055.632l2.682 4.795c.044.391.08.766.107 1.116h.26a33.32 33.32 0 00-.112-1.16l.013-.008-.016-.03a78.19 78.19 0 00-.409-3.011l1.533-3.666-.078-.145-.122-.05v-.002z"
                ></path>
                <path
                    fill="url(#paint39_linear_1_969)"
                    d="M75.877 271.447c-1.48-1.25-4.501-2.482-5.554-2.891.104.14.213.277.326.41 1.593.644 5.158 2.182 5.763 3.444l-.007-.01c.15.277.292.544.424.8h.286a71.875 71.875 0 00-1.692-3.046c1.087-2.723 1.54-3.909 1.726-4.437-.052-.106-.104-.212-.16-.317-.172.522-1.072 2.813-1.734 4.471a96.353 96.353 0 00-1.136-1.887l.025.006c.319-1.133.333-4.442.332-5.29-.087-.047-.173-.096-.262-.138.001.471.005 3.789-.269 5.136-1.264-2.038-2.553-3.963-3.404-4.96a2.82 2.82 0 00-.203.151c.52.629 1.277 1.7 2.11 2.963-.815-.334-2.136-.086-2.953.067a7.936 7.936 0 01-.272.049c.008.073.013.146.026.218.002.013.007.026.01.041.078-.013.17-.031.285-.052.795-.15 2.491-.336 3.187.091"
                ></path>
                <path
                    fill="url(#paint40_linear_1_969)"
                    d="M68.532 268.323a1.95 1.95 0 00-.313.034c.786.929 1.65 2.03 1.888 2.547a12.52 12.52 0 00-3.058-.269c.017.042.031.083.05.124.02.046.046.09.07.134 1.587-.044 4.61.205 7.343 2.309h.418a11.234 11.234 0 00-4.524-2.23c-.16-.541-1.168-1.805-1.876-2.648l.002-.001z"
                ></path>
                <path
                    fill="url(#paint41_linear_1_969)"
                    d="M277.553 199.572h-96.317v60.362h96.317v-60.362z"
                ></path>
                <path
                    fill="url(#paint42_linear_1_969)"
                    d="M182.074 259.934h-74.671v-72.681l35.338-32.168 39.333 32.168v72.681z"
                ></path>
                <path
                    fill="url(#paint43_linear_1_969)"
                    d="M182.076 259.934h-74.673v13.173h74.673v-13.173z"
                ></path>
                <path
                    fill="url(#paint44_linear_1_969)"
                    d="M277.553 259.934h-95.479v13.173h95.479v-13.173z"
                ></path>
                <path
                    fill="url(#paint45_linear_1_969)"
                    d="M280.44 191.295h-98.366v8.277h98.366v-8.277z"
                ></path>
                <path
                    fill="url(#paint46_linear_1_969)"
                    d="M280.442 191.295h-98.368l-39.818-41.917h97.142l41.044 41.917z"
                ></path>
                <path
                    fill="url(#paint47_linear_1_969)"
                    d="M107.406 192.545h-7.018v7.029h7.018v-7.029z"
                ></path>
                <path
                    fill="url(#paint48_linear_1_969)"
                    d="M182.074 191.295l-39.818-41.917-.038.037-.037-.037-41.793 41.917v8.277l41.83-40.789 39.856 40.789v-8.277z"
                ></path>
                <path
                    fill="url(#paint49_linear_1_969)"
                    d="M217.948 170.336l-6.599-8.178v-19.39h6.599v27.568z"
                ></path>
                <path
                    fill="url(#paint50_linear_1_969)"
                    d="M231.259 142.768h-13.311v27.569h13.311v-27.569z"
                ></path>
                <path
                    fill="url(#paint51_linear_1_969)"
                    d="M217.948 138.284h-9.556v4.484h9.556v-4.484z"
                ></path>
                <path
                    fill="url(#paint52_linear_1_969)"
                    d="M234.386 138.284h-16.438v4.484h16.438v-4.484z"
                ></path>
                <path
                    fill="url(#paint53_linear_1_969)"
                    d="M182.074 199.572l-36.016-36.793.033-.031-3.873-3.965-.036.036-.035-.036-1.436 1.47-33.305 32.476v6.843l34.679-33.011 39.989 40.03h95.479v-7.019h-95.479z"
                    opacity="0.2"
                ></path>
                <path
                    fill="#fff"
                    d="M222.145 218.19h-23.192v23.228h23.192V218.19z"
                ></path>
                <path
                    fill="url(#paint54_linear_1_969)"
                    d="M220.586 219.751H200.51v20.107h20.076v-20.107z"
                ></path>
                <path
                    fill="url(#paint55_linear_1_969)"
                    d="M227.965 241.42h-32.084v1.285h32.084v-1.285z"
                ></path>
                <path
                    fill="#fff"
                    d="M220.586 222.919l-17.372 16.939h-2.704v-3.926l16.971-16.147 3.105-.034v3.168zM220.586 239.858h-10.038l10.038-10.053v10.053z"
                    opacity="0.1"
                ></path>
                <path
                    fill="#fff"
                    d="M260.279 218.19h-23.192v23.228h23.192V218.19z"
                ></path>
                <path
                    fill="url(#paint56_linear_1_969)"
                    d="M258.722 219.751h-20.076v20.107h20.076v-20.107z"
                ></path>
                <path
                    fill="url(#paint57_linear_1_969)"
                    d="M266.101 241.42h-32.084v1.285h32.084v-1.285z"
                ></path>
                <path
                    fill="#fff"
                    d="M258.72 222.919l-17.37 16.939h-2.704v-3.926l16.97-16.147 3.104-.034v3.168zM258.72 239.858h-10.036l10.036-10.053v10.053z"
                    opacity="0.1"
                ></path>
                <path
                    fill="url(#paint58_linear_1_969)"
                    d="M156.324 213.206H123.53v46.729h32.794v-46.729z"
                ></path>
                <path
                    fill="url(#paint59_linear_1_969)"
                    d="M161.134 213.206h-32.792v46.729h32.792v-46.729z"
                ></path>
                <path
                    fill="url(#paint60_linear_1_969)"
                    d="M161.134 213.206h-32.792v5.592h32.792v-5.592z"
                ></path>
                <path
                    fill="url(#paint61_linear_1_969)"
                    d="M187.061 159.492h-11.709c-.542 0-.986.65-.986 1.445s.444 1.446.986 1.446h11.709c.542 0 .986-.651.986-1.446 0-.795-.444-1.445-.986-1.445z"
                ></path>
                <path
                    fill="url(#paint62_linear_1_969)"
                    d="M205.761 170.336h-11.709c-.542 0-.986.65-.986 1.445s.444 1.446.986 1.446h11.709c.542 0 .986-.651.986-1.446 0-.795-.444-1.445-.986-1.445z"
                ></path>
                <path
                    fill="url(#paint63_linear_1_969)"
                    d="M246.712 160.939h-11.709c-.542 0-.986.65-.986 1.445 0 .796.444 1.446.986 1.446h11.709c.542 0 .986-.65.986-1.446 0-.795-.444-1.445-.986-1.445z"
                ></path>
                <path
                    fill="url(#paint64_linear_1_969)"
                    d="M259.557 173.883h-11.709c-.542 0-.986.651-.986 1.446 0 .795.444 1.445.986 1.445h11.709c.542 0 .986-.65.986-1.445s-.444-1.446-.986-1.446z"
                ></path>
                <path
                    fill="url(#paint65_linear_1_969)"
                    d="M241.269 181.241H229.56c-.542 0-.986.651-.986 1.446 0 .795.444 1.446.986 1.446h11.709c.542 0 .986-.651.986-1.446 0-.795-.444-1.446-.986-1.446z"
                ></path>
                <path
                    fill="url(#paint66_linear_1_969)"
                    d="M194.766 179.175h-11.709c-.542 0-.986.65-.986 1.446 0 .795.444 1.445.986 1.445h11.709c.542 0 .986-.65.986-1.445 0-.796-.444-1.446-.986-1.446z"
                ></path>
                <path
                    fill="url(#paint67_linear_1_969)"
                    d="M116.245 201.477h7.705c.454 0 .822.369.822.823v1.355a.823.823 0 01-.822.823h-7.705a.822.822 0 01-.821-.823V202.3c0-.454.367-.823.821-.823z"
                    opacity="0.2"
                ></path>
                <path
                    fill="url(#paint68_linear_1_969)"
                    d="M162.996 201.477h7.704c.454 0 .822.369.822.823v1.355a.823.823 0 01-.822.823h-7.704a.823.823 0 01-.822-.823V202.3c0-.454.368-.823.822-.823z"
                    opacity="0.2"
                ></path>
                <path
                    fill="url(#paint69_linear_1_969)"
                    d="M178.219 218.19h-7.706a.822.822 0 00-.822.823v1.356c0 .454.368.823.822.823h7.706a.823.823 0 00.822-.823v-1.356a.822.822 0 00-.822-.823z"
                    opacity="0.2"
                ></path>
                <path
                    fill="url(#paint70_linear_1_969)"
                    d="M112.578 230.706h7.704c.454 0 .822.368.822.823v1.354a.822.822 0 01-.822.823h-7.704a.822.822 0 01-.822-.823v-1.354c0-.455.368-.823.822-.823z"
                    opacity="0.2"
                ></path>
                <path
                    fill="url(#paint71_linear_1_969)"
                    d="M167.672 245.121h7.705c.454 0 .821.368.821.823v1.354a.821.821 0 01-.821.823h-7.705a.822.822 0 01-.822-.823v-1.354c0-.455.368-.823.822-.823z"
                    opacity="0.2"
                ></path>
                <path
                    fill="url(#paint72_linear_1_969)"
                    d="M198.203 211.705h-7.707a.823.823 0 00-.821.823v1.356c0 .455.368.823.821.823h7.707a.821.821 0 00.821-.823v-1.356a.822.822 0 00-.821-.823z"
                    opacity="0.2"
                ></path>
                <path
                    fill="url(#paint73_linear_1_969)"
                    d="M246.206 207.726h7.705c.454 0 .821.368.821.823v1.354a.821.821 0 01-.821.823h-7.705a.822.822 0 01-.822-.823v-1.354c0-.455.368-.823.822-.823z"
                    opacity="0.2"
                ></path>
                <path
                    fill="url(#paint74_linear_1_969)"
                    d="M273.189 219.751h-7.706a.823.823 0 00-.822.823v1.356c0 .455.368.823.822.823h7.706a.822.822 0 00.822-.823v-1.356a.823.823 0 00-.822-.823z"
                    opacity="0.2"
                ></path>
                <path
                    fill="url(#paint75_linear_1_969)"
                    d="M260.808 248.044h7.705c.454 0 .822.369.822.823v1.355a.823.823 0 01-.822.823h-7.705a.823.823 0 01-.822-.823v-1.355c0-.454.368-.823.822-.823z"
                    opacity="0.2"
                ></path>
                <path
                    fill="url(#paint76_linear_1_969)"
                    d="M235.696 252.774h-7.706a.822.822 0 00-.822.823v1.356c0 .454.368.823.822.823h7.706a.823.823 0 00.822-.823v-1.356a.822.822 0 00-.822-.823z"
                    opacity="0.2"
                ></path>
                <path
                    fill="url(#paint77_linear_1_969)"
                    d="M225.542 216.689h7.705c.454 0 .822.368.822.823v1.354a.822.822 0 01-.822.823h-7.705a.821.821 0 01-.821-.823v-1.354c0-.455.367-.823.821-.823z"
                    opacity="0.2"
                ></path>
                <path
                    fill="url(#paint78_linear_1_969)"
                    d="M187.354 249.545h7.705c.454 0 .822.369.822.823v1.354a.822.822 0 01-.822.823h-7.705a.821.821 0 01-.821-.823v-1.354c0-.454.367-.823.821-.823z"
                    opacity="0.2"
                ></path>
                <path
                    fill="url(#paint79_linear_1_969)"
                    d="M138.11 192.548a9.398 9.398 0 017.356-9.177 9.473 9.473 0 00-2.027-.223c-5.185 0-9.386 4.208-9.386 9.4s4.201 9.4 9.386 9.4c.696 0 1.375-.078 2.027-.223a9.398 9.398 0 01-7.356-9.177z"
                ></path>
                <path
                    fill="url(#paint80_linear_1_969)"
                    d="M140.645 192.548c0-4.209 3.262-7.677 7.464-8.151a9.282 9.282 0 00-2.643-1.026 9.398 9.398 0 00-7.356 9.177 9.398 9.398 0 007.356 9.177 9.34 9.34 0 002.643-1.025c-4.202-.474-7.464-3.942-7.464-8.152z"
                ></path>
                <path
                    fill="#fff"
                    d="M140.645 192.548c0-4.209 3.262-7.677 7.464-8.151a9.282 9.282 0 00-2.643-1.026 9.398 9.398 0 00-7.356 9.177 9.398 9.398 0 007.356 9.177 9.34 9.34 0 002.643-1.025c-4.202-.474-7.464-3.942-7.464-8.152z"
                ></path>
                <path
                    fill="url(#paint81_linear_1_969)"
                    d="M140.461 192.548c0 4.21 3.34 7.702 7.607 8.176a9.386 9.386 0 004.756-8.176 9.362 9.362 0 00-4.745-8.169c-4.266.474-7.618 3.958-7.618 8.169z"
                ></path>
                <path
                    fill="url(#paint82_linear_1_969)"
                    d="M161.136 273.107H136.36v-6.584h12.918l.023-6.585h11.835v13.169z"
                ></path>
                <path
                    fill="url(#paint83_linear_1_969)"
                    d="M136.36 266.523H98.99v6.584h37.37v-6.584z"
                ></path>
                <path
                    fill="url(#paint84_linear_1_969)"
                    d="M149.279 259.938h-37.372v6.584h37.372v-6.584z"
                ></path>
                <path
                    fill="url(#paint85_linear_1_969)"
                    d="M380.067 273.107H23.373V276h356.694v-2.893z"
                ></path>
                <path
                    fill="url(#paint86_linear_1_969)"
                    d="M187.911 102.702s1.816-2.236 2.421-2.6c.687-.414.532.388 1.094 1.154.159.683.276 1.363.367 2.049.091.686-1.295.756-1.632.906-1.339.596-2.006 1.986-2.895 2.153-.888.167-1.122-1.5-1.122-1.5l1.767-2.162z"
                ></path>
                <path
                    fill="url(#paint87_linear_1_969)"
                    d="M167.841 115.187s15.493-7.81 18.942-11.68c.487.132 2.05 2.086 2.05 2.086s-8.054 9.538-19.62 17.889c-.046-.795-.735-7.024-1.372-8.294v-.001z"
                ></path>
                <path
                    fill="url(#paint88_linear_1_969)"
                    d="M204.013 139.872v4.124c0 3.478-2.824 6.307-6.297 6.307-3.472 0-6.297-2.829-6.297-6.307V1.454A1.47 1.47 0 00189.953 0c-.804 0-1.458.649-1.466 1.454v142.544c0 5.098 4.141 9.245 9.231 9.245s9.231-4.147 9.231-9.245v-4.125h-2.934l-.002-.001z"
                ></path>
                <path
                    fill="url(#paint89_linear_1_969)"
                    d="M207.343 140.17a1.87 1.87 0 11-3.74 0c0-1.033.838-1.873 1.87-1.873s1.87.84 1.87 1.873z"
                ></path>
                <path
                    fill="url(#paint90_linear_1_969)"
                    d="M111.46 86.015c5.651 0 10.323 2.663 11.095 6.126h.23c.773-3.463 5.444-6.126 11.096-6.126 5.651 0 10.322 2.663 11.095 6.126h.23c.773-3.463 5.444-6.126 11.095-6.126 5.652 0 10.323 2.663 11.096 6.126h.23c.772-3.463 5.444-6.126 11.095-6.126 5.651 0 10.323 2.663 11.096 6.126h.229c.773-3.463 5.445-6.126 11.096-6.126s10.323 2.663 11.096 6.126h.229c.773-3.463 5.445-6.126 11.096-6.126s10.323 2.663 11.096 6.126h.229c.773-3.463 5.444-6.126 11.096-6.126 5.651 0 10.322 2.663 11.095 6.126h.23c.773-3.463 5.444-6.126 11.095-6.126 5.652 0 10.323 2.663 11.096 6.126h.114c-4.011-46.08-42.629-82.23-89.678-82.23-47.05 0-85.668 36.15-89.679 82.23h.114c.773-3.463 5.445-6.126 11.096-6.126h-.008z"
                ></path>
                <path
                    fill="url(#paint91_linear_1_969)"
                    d="M199.485 10.414a90.765 90.765 0 00-19.113 0c-15.523 8.917-27.59 39.075-30.531 76.907 1.826-.822 4.052-1.306 6.459-1.306 5.651 0 10.323 2.663 11.095 6.126h.23c.773-3.463 5.444-6.126 11.096-6.126 5.651 0 10.322 2.663 11.095 6.126h.23c.773-3.463 5.444-6.126 11.095-6.126 5.652 0 10.323 2.663 11.096 6.126h.23c.772-3.463 5.444-6.126 11.095-6.126 2.405 0 4.633.484 6.458 1.306-2.94-37.832-15.007-67.99-30.53-76.907h-.005z"
                    opacity="0.3"
                ></path>
                <path
                    fill="url(#paint92_linear_1_969)"
                    d="M190.282 103.487c.029.222.249.378.489.347l.806-.104c.241-.031.413-.24.384-.461-.029-.222-.249-.378-.49-.347l-.805.104c-.241.031-.414.238-.384.461z"
                ></path>
                <path
                    fill="url(#paint93_linear_1_969)"
                    d="M190.103 102.689c.029.221.249.378.489.347l.806-.105c.241-.031.413-.237.384-.461-.029-.221-.249-.378-.49-.347l-.805.104c-.241.031-.414.238-.384.462z"
                ></path>
                <path
                    fill="url(#paint94_linear_1_969)"
                    d="M190.072 101.871c.029.221.249.378.49.347l.805-.105c.241-.031.413-.238.384-.461-.029-.222-.249-.378-.49-.347l-.805.104c-.241.031-.414.24-.384.462z"
                ></path>
                <path
                    fill="url(#paint95_linear_1_969)"
                    d="M190.119 101.043c.029.221.249.378.49.347l.805-.105c.241-.031.413-.239.384-.461-.029-.221-.249-.378-.49-.347l-.805.104c-.241.031-.413.238-.384.462z"
                ></path>
                <path
                    fill="url(#paint96_linear_1_969)"
                    d="M168.137 167.917l-1.584 3.843s-.998.422-.445 1.348c.554.925 2.373 1.743 2.833 1.739.962-.01-1.111-2.315-1.111-2.315l1.941-4.763-1.634.145v.003z"
                ></path>
                <path
                    fill="#223365"
                    d="M168.107 172.64s2.531 3.043 2.193 3.844c-.542 1.281-4.813-2.589-4.917-3.606-.101-.976 1.168-1.239 1.168-1.239s.133.97 1.556.999v.002z"
                ></path>
                <path
                    fill="url(#paint97_linear_1_969)"
                    d="M169.045 160.233c.5-1.854 1.024-3.743 1.561-5.637-4.286-1.343-15.208-4.931-15.208-4.931.396-1.541 1.094-2.89 1.413-3.478 1.022-1.884 7.25-3.225 9.488-2.921 1.944.266 4.344 1.78 6.105 2.642 2.93 1.434 7.122 4.703 5.415 8.217-2.932 5.766-6.18 12.42-9.206 18.137-.215-.026-2.051-.89-2.243-.983.552-2.237 1.47-6.584 2.674-11.048l.001.002z"
                ></path>
                <path
                    fill="url(#paint98_linear_1_969)"
                    d="M157.562 179.934l-1.815 3.741s-1.022.361-.526 1.318c.496.958 2.262 1.886 2.722 1.908.96.049-.97-2.376-.97-2.376l2.226-4.636-1.638.045h.001z"
                ></path>
                <path
                    fill="#223365"
                    d="M157.247 184.648s2.343 3.191 1.956 3.971c-.62 1.247-4.648-2.876-4.69-3.898-.041-.981 1.242-1.165 1.242-1.165s.075.976 1.492 1.092z"
                ></path>
                <path
                    fill="url(#paint99_linear_1_969)"
                    d="M158.986 172.074c.612-1.82 1.248-3.673 1.901-5.531-3.382-4.006-10.938-18.2-10.938-18.2.488-1.514 2.519-3.23 3.593-3.815 1.881-1.024 3.056.907 5.27 1.348 1.925.382 4.882 7.1 5.99 8.604 2.203 2.989 4.686 8.457 4.186 9.855-.393.932-.519 1.685-.873 2.177-3.277 5.577-7.301 12.394-10.67 17.917-.213-.039-1.635-1.253-1.822-1.357.685-2.2 1.889-6.615 3.362-10.999l.001.001z"
                ></path>
                <path
                    fill="url(#paint100_linear_1_969)"
                    d="M156.63 110.024l.326 7.718s2.997 2.648 4.726 2.507c2.012-.165-1.13-10.391-1.13-10.391l-3.922.166z"
                ></path>
                <path
                    fill="url(#paint101_linear_1_969)"
                    d="M158.902 103.3c-.303.192-4.475 2.347-2.641 7.94.537 2.739 4.273 6.245 6.37-.354 1.243-3.908-.359-9.715-3.729-7.586z"
                ></path>
                <path
                    fill="#223365"
                    d="M165.319 103.525a.668.668 0 00-.711-.686c-.735.06-1.892-.154-3.103-1.094-2.906-2.255-4.712-1.52-6.04.89-.597 1.084-.643 2.379-.506 3.496-.003.008-.008.014-.011.021.19 1.362.665 2.553.665 2.553s.6-.821.81-1.411c1.681-4.121 2.602-.065 5.756-.401 2.668-.283 3.114-2.218 3.139-3.368h.001z"
                ></path>
                <path
                    fill="url(#paint102_linear_1_969)"
                    d="M156.456 107.959s-1.268-1.547-1.286-.059c-.014 1.182.842 1.324.842 1.324l.444-1.265z"
                ></path>
                <path
                    fill="url(#paint103_linear_1_969)"
                    d="M148.291 118.039c.05-.129 1.027-.484 1.165-.554 2.153-1.104 4.274-2.243 6.53-2.43 0 0 1.674 1.851 4.108 1.15 1.741-.5 1.761-1.049 1.878-1.191.381-.458 4.589.041 5.773.163 1.849.191 1.95 15.917.55 28.208-.25.491-16.514 5.637-18.478 4.606-1.949-1.024-2.21-28.205-1.528-29.954l.002.002z"
                ></path>
                <path
                    fill="url(#paint104_linear_1_969)"
                    d="M149.63 147.805c.202-.108 18.686-4.768 18.706-4.705.228.776.141.331.215.716-.251.49-18.356 5.189-18.706 4.61-.174-.29-.444-.499-.215-.621z"
                ></path>
                <path
                    fill="url(#paint105_linear_1_969)"
                    d="M159.616 118.658l-.924-1.737 1.544-.965 1.474 1.012-.672 1.784 1.552 17.893-1.64 3.934-2.652-3.716 1.318-18.205z"
                ></path>
                <path
                    fill="url(#paint106_linear_1_969)"
                    d="M161.913 114.558s-.917 2.317-5.34.293c-1.064.137-1.97.427-1.97.427s2.139 2.358 3.566 2.762c.144-.238 2.086-1.822 2.086-1.822l2.176 1.394s.724-1.232.748-2.662c-.089-.143-.921-.352-1.266-.392z"
                ></path>
                <path
                    fill="url(#paint107_linear_1_969)"
                    d="M160.4 146.31l1.919-.429-.302-1.35-1.918.429.301 1.35z"
                ></path>
                <path
                    fill="url(#paint108_linear_1_969)"
                    d="M148.883 144.922s2.247 3.786 4.045 2.762c2.695-1.531-2.676-4.307-2.676-4.307l-1.371 1.544.002.001z"
                ></path>
                <path
                    fill="url(#paint109_linear_1_969)"
                    d="M148.102 126.992c-.06-2.699.521-7.346.241-8.862-.288-1.566-16.067 17.666.846 27.622l1.969-1.995s-5.127-6.576-3.837-12.399c.533-2.407.794-3.863.783-4.366h-.002z"
                ></path>
                <path
                    fill="url(#paint110_linear_1_969)"
                    d="M296.375 273.107c.516-2.767.244-5.679-.897-8.256-1.982-4.476-6.534-7.736-11.432-8.08-4.898-.344-9.908 2.293-12.3 6.565-1.647 2.94-1.966 6.554-1.012 9.771h25.641z"
                ></path>
                <path
                    fill="url(#paint111_linear_1_969)"
                    d="M280.217 273.076c-2.153-3.627-2.112-12.332-2.11-12.428a.165.165 0 10-.331-.001c-.003.384-.042 8.642 2.057 12.429h.386-.002z"
                ></path>
                <path
                    fill="url(#paint112_linear_1_969)"
                    d="M282.842 273.076h.332c-.122-3.01-.728-9.556-1.014-12.654a91.939 91.939 0 01-.143-1.599.166.166 0 00-.332.002c0 .053.036.441.145 1.626.286 3.097.891 9.643 1.012 12.625z"
                ></path>
                <path
                    fill="url(#paint113_linear_1_969)"
                    d="M286.044 273.076h.345c1.461-4.152 2.001-12.364 1.848-12.669-.04-.081-.144-.124-.227-.083-.082.041-.119.132-.078.213.133.548-.443 8.594-1.888 12.539z"
                ></path>
                <path
                    fill="url(#paint114_linear_1_969)"
                    d="M290.499 273.076c1.359-2.112 2.125-6.701 2.163-6.931a.167.167 0 00-.329-.054c-.01.058-.84 5.061-2.218 6.985h.384z"
                ></path>
                <path
                    fill="url(#paint115_linear_1_969)"
                    d="M276.351 273.076c-1.308-1.592-2.727-4.374-2.989-5.423a.166.166 0 10-.322.08c.24.958 1.545 3.637 2.881 5.342h.43v.001z"
                ></path>
                <path
                    fill="url(#paint116_linear_1_969)"
                    d="M282.404 267.169a.22.22 0 01-.044-.005c-2.132-.574-2.916-5.295-2.948-5.496a.165.165 0 01.138-.189.164.164 0 01.19.137c.008.048.783 4.709 2.708 5.227a.164.164 0 01.117.203.167.167 0 01-.161.123z"
                ></path>
                <path
                    fill="url(#paint117_linear_1_969)"
                    d="M282.572 265.29a.167.167 0 01-.15-.093.164.164 0 01.078-.222c1.609-.776 1.79-4.134 1.792-4.169.004-.091.087-.169.174-.158a.165.165 0 01.157.173c-.006.147-.192 3.59-1.978 4.452a.158.158 0 01-.072.017h-.001z"
                ></path>
                <path
                    fill="url(#paint118_linear_1_969)"
                    d="M279.133 271.424a.167.167 0 01-.057-.01c-2.997-1.076-3.825-6.569-3.825-6.621 0-.093.075-.167.166-.167.091 0 .166.074.166.167.008.41.986 5.369 3.604 6.308a.166.166 0 01-.055.323h.001z"
                ></path>
                <path
                    fill="url(#paint119_linear_1_969)"
                    d="M276.193 267.666a.164.164 0 01-.138-.258c.651-.973.42-4.064.161-4.3a.164.164 0 01-.059-.225.172.172 0 01.23-.061c.117.067.201.228.271.525.231.975.251 3.38-.325 4.244a.17.17 0 01-.138.073l-.002.002z"
                ></path>
                <path
                    fill="url(#paint120_linear_1_969)"
                    d="M274.672 271.879c-1.33 0-2.534-1.148-3.025-1.616-.073-.068-.127-.12-.16-.146a.166.166 0 01-.026-.233.17.17 0 01.235-.026c.037.029.099.088.18.166.524.499 1.915 1.824 3.276 1.462a.164.164 0 01.203.117.168.168 0 01-.117.204 2.23 2.23 0 01-.568.073l.002-.001z"
                ></path>
                <path
                    fill="url(#paint121_linear_1_969)"
                    d="M286.841 270.594a.184.184 0 01-.057-.009c-1.55-.563-1.544-3.687-1.402-5.99.009-.169.018-.293.018-.356 0-.092.074-.167.166-.167.091 0 .166.075.166.167 0 .066-.007.198-.018.378-.207 3.393.192 5.296 1.184 5.656.087.031.13.127.1.214a.167.167 0 01-.157.109v-.002z"
                ></path>
                <path
                    fill="url(#paint122_linear_1_969)"
                    d="M287.561 267.555a.169.169 0 01-.155-.104.164.164 0 01.093-.215c2.209-.89 2.665-4.745 2.67-4.784a.164.164 0 01.184-.146.164.164 0 01.146.184c-.018.166-.481 4.087-2.875 5.054a.166.166 0 01-.062.011h-.001z"
                ></path>
                <path
                    fill="url(#paint123_linear_1_969)"
                    d="M290.999 271.699a.167.167 0 01-.057-.01c-1.336-.484-.85-3.272-.828-3.391a.167.167 0 01.328.059c-.132.73-.278 2.697.614 3.02.086.031.13.125.099.211a.164.164 0 01-.156.11v.001z"
                ></path>
                <path
                    fill="url(#paint124_linear_1_969)"
                    d="M291.554 270.483a.165.165 0 01-.012-.33c1.439-.103 2.786-3.023 2.799-3.054a.17.17 0 01.221-.083.166.166 0 01.083.22c-.058.127-1.446 3.13-3.078 3.246h-.012l-.001.001z"
                ></path>
                <path
                    fill="url(#paint125_linear_1_969)"
                    d="M282.904 271.091h-.014c-1.584-.117-2.702-2.969-2.748-3.09a.165.165 0 11.309-.118c.01.026 1.089 2.777 2.464 2.878a.166.166 0 01.153.177.166.166 0 01-.166.153h.002z"
                ></path>
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_1_969"
                    x1="93.776"
                    x2="93.776"
                    y1="115.632"
                    y2="273.106"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#A0C4E8"></stop>
                    <stop offset="1" stopColor="#67A3D8"></stop>
                </linearGradient>
                <linearGradient
                    id="paint1_linear_1_969"
                    x1="93.294"
                    x2="93.294"
                    y1="147.784"
                    y2="152.968"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#A0C4E8"></stop>
                    <stop offset="1" stopColor="#67A3D8"></stop>
                </linearGradient>
                <linearGradient
                    id="paint2_linear_1_969"
                    x1="68.841"
                    x2="68.841"
                    y1="160.437"
                    y2="163.03"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#A0C4E8"></stop>
                    <stop offset="1" stopColor="#67A3D8"></stop>
                </linearGradient>
                <linearGradient
                    id="paint3_linear_1_969"
                    x1="116.914"
                    x2="116.914"
                    y1="160.437"
                    y2="163.03"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#A0C4E8"></stop>
                    <stop offset="1" stopColor="#67A3D8"></stop>
                </linearGradient>
                <linearGradient
                    id="paint4_linear_1_969"
                    x1="62.324"
                    x2="62.324"
                    y1="167.937"
                    y2="170.53"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#A0C4E8"></stop>
                    <stop offset="1" stopColor="#67A3D8"></stop>
                </linearGradient>
                <linearGradient
                    id="paint5_linear_1_969"
                    x1="97.731"
                    x2="97.731"
                    y1="167.937"
                    y2="170.53"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#A0C4E8"></stop>
                    <stop offset="1" stopColor="#67A3D8"></stop>
                </linearGradient>
                <linearGradient
                    id="paint6_linear_1_969"
                    x1="128.701"
                    x2="128.701"
                    y1="167.937"
                    y2="170.53"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#A0C4E8"></stop>
                    <stop offset="1" stopColor="#67A3D8"></stop>
                </linearGradient>
                <linearGradient
                    id="paint7_linear_1_969"
                    x1="68.841"
                    x2="68.841"
                    y1="176.271"
                    y2="178.862"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#A0C4E8"></stop>
                    <stop offset="1" stopColor="#67A3D8"></stop>
                </linearGradient>
                <linearGradient
                    id="paint8_linear_1_969"
                    x1="116.914"
                    x2="116.914"
                    y1="176.271"
                    y2="178.862"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#A0C4E8"></stop>
                    <stop offset="1" stopColor="#67A3D8"></stop>
                </linearGradient>
                <linearGradient
                    id="paint9_linear_1_969"
                    x1="62.324"
                    x2="62.324"
                    y1="183.771"
                    y2="186.362"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#A0C4E8"></stop>
                    <stop offset="1" stopColor="#67A3D8"></stop>
                </linearGradient>
                <linearGradient
                    id="paint10_linear_1_969"
                    x1="88.995"
                    x2="88.995"
                    y1="183.771"
                    y2="186.362"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#A0C4E8"></stop>
                    <stop offset="1" stopColor="#67A3D8"></stop>
                </linearGradient>
                <linearGradient
                    id="paint11_linear_1_969"
                    x1="106.699"
                    x2="106.699"
                    y1="183.771"
                    y2="186.362"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#A0C4E8"></stop>
                    <stop offset="1" stopColor="#67A3D8"></stop>
                </linearGradient>
                <linearGradient
                    id="paint12_linear_1_969"
                    x1="128.701"
                    x2="128.701"
                    y1="183.771"
                    y2="186.362"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#A0C4E8"></stop>
                    <stop offset="1" stopColor="#67A3D8"></stop>
                </linearGradient>
                <linearGradient
                    id="paint13_linear_1_969"
                    x1="93.294"
                    x2="93.294"
                    y1="197.411"
                    y2="202.597"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#A0C4E8"></stop>
                    <stop offset="1" stopColor="#67A3D8"></stop>
                </linearGradient>
                <linearGradient
                    id="paint14_linear_1_969"
                    x1="68.841"
                    x2="68.841"
                    y1="210.066"
                    y2="212.659"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#A0C4E8"></stop>
                    <stop offset="1" stopColor="#67A3D8"></stop>
                </linearGradient>
                <linearGradient
                    id="paint15_linear_1_969"
                    x1="116.914"
                    x2="116.914"
                    y1="210.066"
                    y2="212.659"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#A0C4E8"></stop>
                    <stop offset="1" stopColor="#67A3D8"></stop>
                </linearGradient>
                <linearGradient
                    id="paint16_linear_1_969"
                    x1="62.324"
                    x2="62.324"
                    y1="217.566"
                    y2="220.159"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#A0C4E8"></stop>
                    <stop offset="1" stopColor="#67A3D8"></stop>
                </linearGradient>
                <linearGradient
                    id="paint17_linear_1_969"
                    x1="97.731"
                    x2="97.731"
                    y1="217.566"
                    y2="220.159"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#A0C4E8"></stop>
                    <stop offset="1" stopColor="#67A3D8"></stop>
                </linearGradient>
                <linearGradient
                    id="paint18_linear_1_969"
                    x1="128.701"
                    x2="128.701"
                    y1="217.566"
                    y2="220.159"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#A0C4E8"></stop>
                    <stop offset="1" stopColor="#67A3D8"></stop>
                </linearGradient>
                <linearGradient
                    id="paint19_linear_1_969"
                    x1="68.841"
                    x2="68.841"
                    y1="225.898"
                    y2="228.491"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#A0C4E8"></stop>
                    <stop offset="1" stopColor="#67A3D8"></stop>
                </linearGradient>
                <linearGradient
                    id="paint20_linear_1_969"
                    x1="116.914"
                    x2="116.914"
                    y1="225.898"
                    y2="228.491"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#A0C4E8"></stop>
                    <stop offset="1" stopColor="#67A3D8"></stop>
                </linearGradient>
                <linearGradient
                    id="paint21_linear_1_969"
                    x1="62.324"
                    x2="62.324"
                    y1="233.398"
                    y2="235.991"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#A0C4E8"></stop>
                    <stop offset="1" stopColor="#67A3D8"></stop>
                </linearGradient>
                <linearGradient
                    id="paint22_linear_1_969"
                    x1="88.995"
                    x2="88.995"
                    y1="233.398"
                    y2="235.991"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#A0C4E8"></stop>
                    <stop offset="1" stopColor="#67A3D8"></stop>
                </linearGradient>
                <linearGradient
                    id="paint23_linear_1_969"
                    x1="106.699"
                    x2="106.699"
                    y1="233.398"
                    y2="235.991"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#A0C4E8"></stop>
                    <stop offset="1" stopColor="#67A3D8"></stop>
                </linearGradient>
                <linearGradient
                    id="paint24_linear_1_969"
                    x1="128.701"
                    x2="128.701"
                    y1="233.398"
                    y2="235.991"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#A0C4E8"></stop>
                    <stop offset="1" stopColor="#67A3D8"></stop>
                </linearGradient>
                <linearGradient
                    id="paint25_linear_1_969"
                    x1="127.818"
                    x2="127.818"
                    y1="240.212"
                    y2="449.965"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#A0C4E8"></stop>
                    <stop offset="1" stopColor="#67A3D8"></stop>
                </linearGradient>
                <linearGradient
                    id="paint26_linear_1_969"
                    x1="59.592"
                    x2="59.592"
                    y1="252.317"
                    y2="272.002"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#A0C4E8"></stop>
                    <stop offset="1" stopColor="#67A3D8"></stop>
                </linearGradient>
                <linearGradient
                    id="paint27_linear_1_969"
                    x1="89.796"
                    x2="89.796"
                    y1="252.317"
                    y2="272.002"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#A0C4E8"></stop>
                    <stop offset="1" stopColor="#67A3D8"></stop>
                </linearGradient>
                <linearGradient
                    id="paint28_linear_1_969"
                    x1="247.919"
                    x2="381.5"
                    y1="180.12"
                    y2="180.12"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#75ABDC"></stop>
                    <stop offset="1" stopColor="#406FAD"></stop>
                </linearGradient>
                <linearGradient
                    id="paint29_linear_1_969"
                    x1="248.896"
                    x2="314.711"
                    y1="180.12"
                    y2="180.12"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#75ABDC"></stop>
                    <stop offset="1" stopColor="#406FAD"></stop>
                </linearGradient>
                <linearGradient
                    id="paint30_linear_1_969"
                    x1="238.944"
                    x2="390.474"
                    y1="180.122"
                    y2="180.122"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#355D92"></stop>
                    <stop offset="1" stopColor="#0A2947"></stop>
                </linearGradient>
                <linearGradient
                    id="paint31_linear_1_969"
                    x1="66.921"
                    x2="100.685"
                    y1="267.71"
                    y2="267.71"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#D7EDFA"></stop>
                    <stop offset="1" stopColor="#A7CFEE"></stop>
                </linearGradient>
                <linearGradient
                    id="paint32_linear_1_969"
                    x1="76.514"
                    x2="78.036"
                    y1="262.384"
                    y2="262.384"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#D7EDFA"></stop>
                    <stop offset="1" stopColor="#A7CFEE"></stop>
                </linearGradient>
                <linearGradient
                    id="paint33_linear_1_969"
                    x1="82.368"
                    x2="83.596"
                    y1="262.702"
                    y2="262.702"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#D7EDFA"></stop>
                    <stop offset="1" stopColor="#A7CFEE"></stop>
                </linearGradient>
                <linearGradient
                    id="paint34_linear_1_969"
                    x1="93.636"
                    x2="95.095"
                    y1="266.339"
                    y2="266.339"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#D7EDFA"></stop>
                    <stop offset="1" stopColor="#A7CFEE"></stop>
                </linearGradient>
                <linearGradient
                    id="paint35_linear_1_969"
                    x1="88.098"
                    x2="92.715"
                    y1="269.748"
                    y2="269.748"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#68A9DC"></stop>
                    <stop offset="1" stopColor="#3A6EAE"></stop>
                </linearGradient>
                <linearGradient
                    id="paint36_linear_1_969"
                    x1="84.074"
                    x2="88.961"
                    y1="267.799"
                    y2="267.799"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#68A9DC"></stop>
                    <stop offset="1" stopColor="#3A6EAE"></stop>
                </linearGradient>
                <linearGradient
                    id="paint37_linear_1_969"
                    x1="93.335"
                    x2="100.665"
                    y1="270.361"
                    y2="270.361"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#68A9DC"></stop>
                    <stop offset="1" stopColor="#3A6EAE"></stop>
                </linearGradient>
                <linearGradient
                    id="paint38_linear_1_969"
                    x1="77.932"
                    x2="82.045"
                    y1="268.556"
                    y2="268.556"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#68A9DC"></stop>
                    <stop offset="1" stopColor="#3A6EAE"></stop>
                </linearGradient>
                <linearGradient
                    id="paint39_linear_1_969"
                    x1="69.222"
                    x2="77.147"
                    y1="267.879"
                    y2="267.879"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#68A9DC"></stop>
                    <stop offset="1" stopColor="#3A6EAE"></stop>
                </linearGradient>
                <linearGradient
                    id="paint40_linear_1_969"
                    x1="67.049"
                    x2="74.93"
                    y1="270.761"
                    y2="270.761"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#68A9DC"></stop>
                    <stop offset="1" stopColor="#3A6EAE"></stop>
                </linearGradient>
                <linearGradient
                    id="paint41_linear_1_969"
                    x1="181.236"
                    x2="277.553"
                    y1="229.754"
                    y2="229.754"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#68A9DC"></stop>
                    <stop offset="1" stopColor="#3A6EAE"></stop>
                </linearGradient>
                <linearGradient
                    id="paint42_linear_1_969"
                    x1="107.403"
                    x2="182.074"
                    y1="207.511"
                    y2="207.511"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#A0C4E8"></stop>
                    <stop offset="1" stopColor="#67A3D8"></stop>
                </linearGradient>
                <linearGradient
                    id="paint43_linear_1_969"
                    x1="144.739"
                    x2="144.739"
                    y1="259.934"
                    y2="273.107"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#2F5B91"></stop>
                    <stop offset="1" stopColor="#00264C"></stop>
                </linearGradient>
                <linearGradient
                    id="paint44_linear_1_969"
                    x1="229.814"
                    x2="229.814"
                    y1="0"
                    y2="0"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#2F5B91"></stop>
                    <stop offset="1" stopColor="#00264C"></stop>
                </linearGradient>
                <linearGradient
                    id="paint45_linear_1_969"
                    x1="231.259"
                    x2="231.259"
                    y1="199.572"
                    y2="191.295"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#DDBCC2"></stop>
                    <stop offset="0.2" stopColor="#DBB2B9"></stop>
                    <stop offset="0.61" stopColor="#D9A6AE"></stop>
                    <stop offset="1" stopColor="#D9A2AB"></stop>
                </linearGradient>
                <linearGradient
                    id="paint46_linear_1_969"
                    x1="211.349"
                    x2="211.349"
                    y1="191.295"
                    y2="149.378"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#DDBCC2"></stop>
                    <stop offset="1" stopColor="#D9A2AB"></stop>
                </linearGradient>
                <linearGradient
                    id="paint47_linear_1_969"
                    x1="100.388"
                    x2="107.406"
                    y1="196.059"
                    y2="196.059"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#DDBCC2"></stop>
                    <stop offset="1" stopColor="#D9A2AB"></stop>
                </linearGradient>
                <linearGradient
                    id="paint48_linear_1_969"
                    x1="100.388"
                    x2="182.074"
                    y1="174.475"
                    y2="174.475"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FBD4DA"></stop>
                    <stop offset="1" stopColor="#EDB0BA"></stop>
                </linearGradient>
                <linearGradient
                    id="paint49_linear_1_969"
                    x1="214.649"
                    x2="214.649"
                    y1="142.768"
                    y2="170.336"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FBD4DA"></stop>
                    <stop offset="1" stopColor="#EDB0BA"></stop>
                </linearGradient>
                <linearGradient
                    id="paint50_linear_1_969"
                    x1="224.604"
                    x2="224.604"
                    y1="142.768"
                    y2="170.336"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#DDBCC2"></stop>
                    <stop offset="1" stopColor="#D9A2AB"></stop>
                </linearGradient>
                <linearGradient
                    id="paint51_linear_1_969"
                    x1="208.392"
                    x2="217.948"
                    y1="140.525"
                    y2="140.525"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FBD4DA"></stop>
                    <stop offset="1" stopColor="#EDB0BA"></stop>
                </linearGradient>
                <linearGradient
                    id="paint52_linear_1_969"
                    x1="217.948"
                    x2="234.386"
                    y1="140.525"
                    y2="140.525"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#DDBCC2"></stop>
                    <stop offset="1" stopColor="#D9A2AB"></stop>
                </linearGradient>
                <linearGradient
                    id="paint53_linear_1_969"
                    x1="213.786"
                    x2="171.151"
                    y1="162.699"
                    y2="236.433"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#2F5B91"></stop>
                    <stop offset="1" stopColor="#00264C"></stop>
                </linearGradient>
                <linearGradient
                    id="paint54_linear_1_969"
                    x1="217.405"
                    x2="203.661"
                    y1="217.911"
                    y2="241.68"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#D7EDFA"></stop>
                    <stop offset="1" stopColor="#A7CFEE"></stop>
                </linearGradient>
                <linearGradient
                    id="paint55_linear_1_969"
                    x1="216.212"
                    x2="207.615"
                    y1="234.622"
                    y2="249.49"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#2F5B91"></stop>
                    <stop offset="1" stopColor="#00264C"></stop>
                </linearGradient>
                <linearGradient
                    id="paint56_linear_1_969"
                    x1="255.539"
                    x2="241.795"
                    y1="217.911"
                    y2="241.68"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#D7EDFA"></stop>
                    <stop offset="1" stopColor="#A7CFEE"></stop>
                </linearGradient>
                <linearGradient
                    id="paint57_linear_1_969"
                    x1="254.347"
                    x2="245.75"
                    y1="234.622"
                    y2="249.49"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#2F5B91"></stop>
                    <stop offset="1" stopColor="#00264C"></stop>
                </linearGradient>
                <linearGradient
                    id="paint58_linear_1_969"
                    x1="154.126"
                    x2="125.659"
                    y1="211.937"
                    y2="261.166"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#68A9DC"></stop>
                    <stop offset="1" stopColor="#3A6EAE"></stop>
                </linearGradient>
                <linearGradient
                    id="paint59_linear_1_969"
                    x1="158.939"
                    x2="130.472"
                    y1="211.937"
                    y2="261.166"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#2F5B91"></stop>
                    <stop offset="1" stopColor="#00264C"></stop>
                </linearGradient>
                <linearGradient
                    id="paint60_linear_1_969"
                    x1="150.046"
                    x2="139.406"
                    y1="206.793"
                    y2="225.196"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#2F5B91"></stop>
                    <stop offset="1" stopColor="#00264C"></stop>
                </linearGradient>
                <linearGradient
                    id="paint61_linear_1_969"
                    x1="181.207"
                    x2="181.207"
                    y1="162.384"
                    y2="159.492"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#DDBCC2"></stop>
                    <stop offset="1" stopColor="#D9A2AB"></stop>
                </linearGradient>
                <linearGradient
                    id="paint62_linear_1_969"
                    x1="199.908"
                    x2="199.908"
                    y1="173.228"
                    y2="170.336"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#DDBCC2"></stop>
                    <stop offset="1" stopColor="#D9A2AB"></stop>
                </linearGradient>
                <linearGradient
                    id="paint63_linear_1_969"
                    x1="240.857"
                    x2="240.857"
                    y1="163.83"
                    y2="160.939"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#DDBCC2"></stop>
                    <stop offset="1" stopColor="#D9A2AB"></stop>
                </linearGradient>
                <linearGradient
                    id="paint64_linear_1_969"
                    x1="253.702"
                    x2="253.702"
                    y1="176.776"
                    y2="173.883"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#DDBCC2"></stop>
                    <stop offset="1" stopColor="#D9A2AB"></stop>
                </linearGradient>
                <linearGradient
                    id="paint65_linear_1_969"
                    x1="235.414"
                    x2="235.414"
                    y1="184.134"
                    y2="181.241"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#DDBCC2"></stop>
                    <stop offset="1" stopColor="#D9A2AB"></stop>
                </linearGradient>
                <linearGradient
                    id="paint66_linear_1_969"
                    x1="188.913"
                    x2="188.913"
                    y1="182.068"
                    y2="179.175"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#DDBCC2"></stop>
                    <stop offset="1" stopColor="#D9A2AB"></stop>
                </linearGradient>
                <linearGradient
                    id="paint67_linear_1_969"
                    x1="120.097"
                    x2="120.097"
                    y1="204.479"
                    y2="201.477"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#2F5B91"></stop>
                    <stop offset="1" stopColor="#00264C"></stop>
                </linearGradient>
                <linearGradient
                    id="paint68_linear_1_969"
                    x1="166.849"
                    x2="166.849"
                    y1="204.479"
                    y2="201.477"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#2F5B91"></stop>
                    <stop offset="1" stopColor="#00264C"></stop>
                </linearGradient>
                <linearGradient
                    id="paint69_linear_1_969"
                    x1="174.366"
                    x2="174.366"
                    y1="221.193"
                    y2="218.19"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#2F5B91"></stop>
                    <stop offset="1" stopColor="#00264C"></stop>
                </linearGradient>
                <linearGradient
                    id="paint70_linear_1_969"
                    x1="116.431"
                    x2="116.431"
                    y1="233.708"
                    y2="230.706"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#2F5B91"></stop>
                    <stop offset="1" stopColor="#00264C"></stop>
                </linearGradient>
                <linearGradient
                    id="paint71_linear_1_969"
                    x1="171.524"
                    x2="171.524"
                    y1="248.122"
                    y2="245.121"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#2F5B91"></stop>
                    <stop offset="1" stopColor="#00264C"></stop>
                </linearGradient>
                <linearGradient
                    id="paint72_linear_1_969"
                    x1="194.35"
                    x2="194.35"
                    y1="214.707"
                    y2="211.705"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#2F5B91"></stop>
                    <stop offset="1" stopColor="#00264C"></stop>
                </linearGradient>
                <linearGradient
                    id="paint73_linear_1_969"
                    x1="250.059"
                    x2="250.059"
                    y1="210.728"
                    y2="207.726"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#2F5B91"></stop>
                    <stop offset="1" stopColor="#00264C"></stop>
                </linearGradient>
                <linearGradient
                    id="paint74_linear_1_969"
                    x1="269.336"
                    x2="269.336"
                    y1="222.753"
                    y2="219.751"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#2F5B91"></stop>
                    <stop offset="1" stopColor="#00264C"></stop>
                </linearGradient>
                <linearGradient
                    id="paint75_linear_1_969"
                    x1="264.661"
                    x2="264.661"
                    y1="251.046"
                    y2="248.044"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#2F5B91"></stop>
                    <stop offset="1" stopColor="#00264C"></stop>
                </linearGradient>
                <linearGradient
                    id="paint76_linear_1_969"
                    x1="231.843"
                    x2="231.843"
                    y1="255.776"
                    y2="252.774"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#2F5B91"></stop>
                    <stop offset="1" stopColor="#00264C"></stop>
                </linearGradient>
                <linearGradient
                    id="paint77_linear_1_969"
                    x1="229.396"
                    x2="229.396"
                    y1="219.691"
                    y2="216.689"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#2F5B91"></stop>
                    <stop offset="1" stopColor="#00264C"></stop>
                </linearGradient>
                <linearGradient
                    id="paint78_linear_1_969"
                    x1="191.206"
                    x2="191.206"
                    y1="252.547"
                    y2="249.545"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#2F5B91"></stop>
                    <stop offset="1" stopColor="#00264C"></stop>
                </linearGradient>
                <linearGradient
                    id="paint79_linear_1_969"
                    x1="139.76"
                    x2="139.76"
                    y1="202.279"
                    y2="182.817"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#2F5B91"></stop>
                    <stop offset="1" stopColor="#00264C"></stop>
                </linearGradient>
                <linearGradient
                    id="paint80_linear_1_969"
                    x1="143.11"
                    x2="143.11"
                    y1="202.279"
                    y2="182.817"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#2F5B91"></stop>
                    <stop offset="1" stopColor="#00264C"></stop>
                </linearGradient>
                <linearGradient
                    id="paint81_linear_1_969"
                    x1="146.642"
                    x2="146.642"
                    y1="201.072"
                    y2="184.032"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#D7EDFA"></stop>
                    <stop offset="1" stopColor="#A7CFEE"></stop>
                </linearGradient>
                <linearGradient
                    id="paint82_linear_1_969"
                    x1="148.748"
                    x2="148.748"
                    y1="259.938"
                    y2="273.107"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#68A9DC"></stop>
                    <stop offset="1" stopColor="#3A6EAE"></stop>
                </linearGradient>
                <linearGradient
                    id="paint83_linear_1_969"
                    x1="98.989"
                    x2="136.36"
                    y1="269.815"
                    y2="269.815"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#A0C4E8"></stop>
                    <stop offset="1" stopColor="#67A3D8"></stop>
                </linearGradient>
                <linearGradient
                    id="paint84_linear_1_969"
                    x1="111.907"
                    x2="149.279"
                    y1="263.23"
                    y2="263.23"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#A0C4E8"></stop>
                    <stop offset="1" stopColor="#67A3D8"></stop>
                </linearGradient>
                <linearGradient
                    id="paint85_linear_1_969"
                    x1="201.721"
                    x2="201.721"
                    y1="273.107"
                    y2="276"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#DDBCC2"></stop>
                    <stop offset="1" stopColor="#D9A2AB"></stop>
                </linearGradient>
                <linearGradient
                    id="paint86_linear_1_969"
                    x1="185.985"
                    x2="191.742"
                    y1="103.597"
                    y2="102.812"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FBD5DB"></stop>
                    <stop offset="1" stopColor="#ECAFB9"></stop>
                </linearGradient>
                <linearGradient
                    id="paint87_linear_1_969"
                    x1="178.09"
                    x2="178.798"
                    y1="103.743"
                    y2="123.048"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#A0C4E8"></stop>
                    <stop offset="1" stopColor="#67A3D8"></stop>
                </linearGradient>
                <linearGradient
                    id="paint88_linear_1_969"
                    x1="197.716"
                    x2="197.716"
                    y1="0"
                    y2="153.242"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#2F5C91"></stop>
                    <stop offset="1" stopColor="#10274A"></stop>
                </linearGradient>
                <linearGradient
                    id="paint89_linear_1_969"
                    x1="205.471"
                    x2="205.471"
                    y1="138.297"
                    y2="142.044"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#2F5C91"></stop>
                    <stop offset="1" stopColor="#10274A"></stop>
                </linearGradient>
                <linearGradient
                    id="paint90_linear_1_969"
                    x1="189.929"
                    x2="189.929"
                    y1="9.91"
                    y2="92.141"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#2F5C91"></stop>
                    <stop offset="1" stopColor="#10274A"></stop>
                </linearGradient>
                <linearGradient
                    id="paint91_linear_1_969"
                    x1="189.929"
                    x2="189.929"
                    y1="9.91"
                    y2="92.141"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#A0C4E6"></stop>
                    <stop offset="1" stopColor="#68A1D6"></stop>
                </linearGradient>
                <linearGradient
                    id="paint92_linear_1_969"
                    x1="188.222"
                    x2="187.062"
                    y1="108.539"
                    y2="107.688"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FBD5DB"></stop>
                    <stop offset="1" stopColor="#ECAFB9"></stop>
                </linearGradient>
                <linearGradient
                    id="paint93_linear_1_969"
                    x1="188.043"
                    x2="186.883"
                    y1="107.744"
                    y2="106.894"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FBD5DB"></stop>
                    <stop offset="1" stopColor="#ECAFB9"></stop>
                </linearGradient>
                <linearGradient
                    id="paint94_linear_1_969"
                    x1="188.013"
                    x2="186.853"
                    y1="106.93"
                    y2="106.079"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FBD5DB"></stop>
                    <stop offset="1" stopColor="#ECAFB9"></stop>
                </linearGradient>
                <linearGradient
                    id="paint95_linear_1_969"
                    x1="188.06"
                    x2="186.901"
                    y1="106.106"
                    y2="105.254"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FBD5DB"></stop>
                    <stop offset="1" stopColor="#ECAFB9"></stop>
                </linearGradient>
                <linearGradient
                    id="paint96_linear_1_969"
                    x1="166.994"
                    x2="170.559"
                    y1="170.128"
                    y2="171.921"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#D5EBF9"></stop>
                    <stop offset="1" stopColor="#A7CEEE"></stop>
                </linearGradient>
                <linearGradient
                    id="paint97_linear_1_969"
                    x1="195.61"
                    x2="153.579"
                    y1="177.052"
                    y2="147.239"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#2F5C91"></stop>
                    <stop offset="1" stopColor="#10274A"></stop>
                </linearGradient>
                <linearGradient
                    id="paint98_linear_1_969"
                    x1="156.252"
                    x2="159.703"
                    y1="182.124"
                    y2="184.131"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#D5EBF9"></stop>
                    <stop offset="1" stopColor="#A7CEEE"></stop>
                </linearGradient>
                <linearGradient
                    id="paint99_linear_1_969"
                    x1="187.834"
                    x2="142.879"
                    y1="182.32"
                    y2="156.403"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#2F5C91"></stop>
                    <stop offset="1" stopColor="#10274A"></stop>
                </linearGradient>
                <linearGradient
                    id="paint100_linear_1_969"
                    x1="162.353"
                    x2="156.628"
                    y1="115.055"
                    y2="115.055"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#DBBBC2"></stop>
                    <stop offset="1" stopColor="#D9A1AB"></stop>
                </linearGradient>
                <linearGradient
                    id="paint101_linear_1_969"
                    x1="163.049"
                    x2="155.869"
                    y1="109.399"
                    y2="107.567"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FBD5DB"></stop>
                    <stop offset="1" stopColor="#ECAFB9"></stop>
                </linearGradient>
                <linearGradient
                    id="paint102_linear_1_969"
                    x1="155.142"
                    x2="156.411"
                    y1="107.908"
                    y2="108.232"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FBD5DB"></stop>
                    <stop offset="1" stopColor="#ECAFB9"></stop>
                </linearGradient>
                <linearGradient
                    id="paint103_linear_1_969"
                    x1="169.25"
                    x2="147.949"
                    y1="131.477"
                    y2="131.477"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#A0C4E8"></stop>
                    <stop offset="1" stopColor="#67A3D8"></stop>
                </linearGradient>
                <linearGradient
                    id="paint104_linear_1_969"
                    x1="162.849"
                    x2="155.359"
                    y1="149.529"
                    y2="142.051"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#2F5C91"></stop>
                    <stop offset="1" stopColor="#10274A"></stop>
                </linearGradient>
                <linearGradient
                    id="paint105_linear_1_969"
                    x1="162.59"
                    x2="158.298"
                    y1="128.267"
                    y2="128.267"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#2F5C91"></stop>
                    <stop offset="1" stopColor="#10274A"></stop>
                </linearGradient>
                <linearGradient
                    id="paint106_linear_1_969"
                    x1="162.659"
                    x2="156.08"
                    y1="114.048"
                    y2="117.841"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#A0C4E8"></stop>
                    <stop offset="1" stopColor="#67A3D8"></stop>
                </linearGradient>
                <linearGradient
                    id="paint107_linear_1_969"
                    x1="162.155"
                    x2="159.99"
                    y1="146.365"
                    y2="145.338"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#2F5C91"></stop>
                    <stop offset="1" stopColor="#10274A"></stop>
                </linearGradient>
                <linearGradient
                    id="paint108_linear_1_969"
                    x1="148.931"
                    x2="153.608"
                    y1="145.78"
                    y2="145.485"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FBD4DA"></stop>
                    <stop offset="1" stopColor="#ECAFB8"></stop>
                </linearGradient>
                <linearGradient
                    id="paint109_linear_1_969"
                    x1="141.287"
                    x2="150.396"
                    y1="132.378"
                    y2="131.803"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#A0C4E8"></stop>
                    <stop offset="1" stopColor="#67A3D8"></stop>
                </linearGradient>
                <linearGradient
                    id="paint110_linear_1_969"
                    x1="270.2"
                    x2="296.626"
                    y1="264.925"
                    y2="264.925"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#A0C4E8"></stop>
                    <stop offset="1" stopColor="#67A3D8"></stop>
                </linearGradient>
                <linearGradient
                    id="paint111_linear_1_969"
                    x1="277.773"
                    x2="280.217"
                    y1="266.779"
                    y2="266.779"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#366293"></stop>
                    <stop offset="1" stopColor="#082946"></stop>
                </linearGradient>
                <linearGradient
                    id="paint112_linear_1_969"
                    x1="281.683"
                    x2="283.174"
                    y1="265.867"
                    y2="265.867"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#366293"></stop>
                    <stop offset="1" stopColor="#082946"></stop>
                </linearGradient>
                <linearGradient
                    id="paint113_linear_1_969"
                    x1="286.044"
                    x2="288.262"
                    y1="266.693"
                    y2="266.693"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#366293"></stop>
                    <stop offset="1" stopColor="#082946"></stop>
                </linearGradient>
                <linearGradient
                    id="paint114_linear_1_969"
                    x1="290.115"
                    x2="292.663"
                    y1="269.514"
                    y2="269.514"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#366293"></stop>
                    <stop offset="1" stopColor="#082946"></stop>
                </linearGradient>
                <linearGradient
                    id="paint115_linear_1_969"
                    x1="273.033"
                    x2="276.351"
                    y1="270.301"
                    y2="270.301"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#366293"></stop>
                    <stop offset="1" stopColor="#082946"></stop>
                </linearGradient>
                <linearGradient
                    id="paint116_linear_1_969"
                    x1="279.41"
                    x2="282.57"
                    y1="264.322"
                    y2="264.322"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#366293"></stop>
                    <stop offset="1" stopColor="#082946"></stop>
                </linearGradient>
                <linearGradient
                    id="paint117_linear_1_969"
                    x1="282.404"
                    x2="284.622"
                    y1="262.967"
                    y2="262.967"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#366293"></stop>
                    <stop offset="1" stopColor="#082946"></stop>
                </linearGradient>
                <linearGradient
                    id="paint118_linear_1_969"
                    x1="275.251"
                    x2="279.299"
                    y1="268.024"
                    y2="268.024"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#366293"></stop>
                    <stop offset="1" stopColor="#082946"></stop>
                </linearGradient>
                <linearGradient
                    id="paint119_linear_1_969"
                    x1="276.027"
                    x2="276.81"
                    y1="265.234"
                    y2="265.234"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#366293"></stop>
                    <stop offset="1" stopColor="#082946"></stop>
                </linearGradient>
                <linearGradient
                    id="paint120_linear_1_969"
                    x1="271.425"
                    x2="275.362"
                    y1="270.85"
                    y2="270.85"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#366293"></stop>
                    <stop offset="1" stopColor="#082946"></stop>
                </linearGradient>
                <linearGradient
                    id="paint121_linear_1_969"
                    x1="285.322"
                    x2="287.007"
                    y1="267.333"
                    y2="267.333"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#366293"></stop>
                    <stop offset="1" stopColor="#082946"></stop>
                </linearGradient>
                <linearGradient
                    id="paint122_linear_1_969"
                    x1="287.395"
                    x2="290.501"
                    y1="264.929"
                    y2="264.929"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#366293"></stop>
                    <stop offset="1" stopColor="#082946"></stop>
                </linearGradient>
                <linearGradient
                    id="paint123_linear_1_969"
                    x1="290.011"
                    x2="291.166"
                    y1="269.931"
                    y2="269.931"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#366293"></stop>
                    <stop offset="1" stopColor="#082946"></stop>
                </linearGradient>
                <linearGradient
                    id="paint124_linear_1_969"
                    x1="291.388"
                    x2="294.66"
                    y1="268.743"
                    y2="268.743"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#366293"></stop>
                    <stop offset="1" stopColor="#082946"></stop>
                </linearGradient>
                <linearGradient
                    id="paint125_linear_1_969"
                    x1="280.131"
                    x2="283.069"
                    y1="269.434"
                    y2="269.434"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#366293"></stop>
                    <stop offset="1" stopColor="#082946"></stop>
                </linearGradient>
                <clipPath id="clip0_1_969">
                    <path fill="#fff" d="M0 0H398V276H0z"></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default SideImage;
