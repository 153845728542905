import React, { ReactElement } from "react";
import SideImage from "./sideImage";

export default function HowIsDifferent(): ReactElement {
    return (
        <section className="flex flex-col lg:flex-row lg:gap-36 gap-16 lg:mt-36 mt-16">
            <div className="max-w-[574px]  mx-auto lg:mx-0 order-1 lg:order-2">
                <h2 className="font-bold lg:text-3xl text-2xl lg:leading-[45px] text-[#5692C4]">
                    How Is a Home Warranty Different From Homeowners' Insurance?
                </h2>
                <p className="mt-5   lg:text-lg text-base lg:leading-8 text-[#232323] font-light">
                    While homeowners' insurance primarily covers property damage
                    and the structure of your house, a home warranty is designed
                    to provide protection for your appliances and systems. It's
                    a smart investment to ensure the safety of your home, but it
                    should not be considered a replacement for homeowners'
                    insurance. It's worth noting that manufacturer warranties
                    may provide coverage for certain home systems in the case of
                    recent home purchases.
                </p>
                <span className="w-[122px] h-[8px] block bg-[#F4C2CA] mt-7"></span>
            </div>
            <div className="w-[300px] h-[210px] md:h-[314px] md:w-[450px] md:min-w-[450px] relative mx-auto lg:mx-0 order-2 lg:order-1 lg:left-[50px] lg:top-[30px]">
                <SideImage />
            </div>
        </section>
    );
}
