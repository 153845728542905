import React from "react";

function IncreasesValue() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="90"
            height="90"
            fill="none"
            viewBox="0 0 90 90"
        >
            <mask
                id="mask0_1_1177"
                style={{ maskType: "alpha" }}
                width="90"
                height="90"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#5692C4" d="M0 0H90V90H0z"></path>
            </mask>
            <g clipPath="url(#clip0_1_1177)" mask="url(#mask0_1_1177)">
                <path
                    fill="#5692C4"
                    d="M74.126 82.663c-2.537 0-4.68-2.135-4.68-4.664v-42.07c0-2.53 2.143-4.669 4.68-4.669h6.382c2.54 0 4.685 2.14 4.685 4.67v42.069c0 2.529-2.146 4.664-4.685 4.664h-6.382zm0-48.295c-.912 0-1.575.656-1.575 1.562v42.069c0 .903.663 1.557 1.575 1.557h6.382c.913 0 1.575-.654 1.575-1.557v-42.07c0-.904-.662-1.561-1.575-1.561h-6.382zM52.578 82.663c-2.54 0-4.685-2.135-4.685-4.664V45.597c0-2.53 2.146-4.67 4.685-4.67h6.4c2.538 0 4.68 2.14 4.68 4.67V78c0 2.529-2.142 4.664-4.68 4.664h-6.4zm0-38.642c-.913 0-1.575.663-1.575 1.576V78c0 .903.662 1.557 1.575 1.557h6.4c.913 0 1.575-.654 1.575-1.557V45.597c0-.913-.662-1.576-1.574-1.576h-6.401zM31.024 82.663A4.668 4.668 0 0126.364 78v-22.73c0-2.54 2.133-4.688 4.66-4.688h6.4c2.538 0 4.684 2.148 4.684 4.689v22.729c0 2.529-2.146 4.664-4.685 4.664h-6.4zm0-28.974c-.902 0-1.556.664-1.556 1.58V78c0 .916.64 1.557 1.556 1.557h6.4c.912 0 1.574-.654 1.574-1.557v-22.73c0-.916-.662-1.58-1.575-1.58h-6.4zM9.494 82.663c-2.539 0-4.684-2.135-4.684-4.664V64.937c0-2.54 2.145-4.683 4.684-4.683h6.4c2.526 0 4.66 2.144 4.66 4.683V78a4.668 4.668 0 01-4.66 4.664h-6.4zm0-19.302c-.916 0-1.58.663-1.58 1.576V78c0 .903.664 1.557 1.58 1.557h6.4c.915 0 1.555-.64 1.555-1.557V64.937c0-.913-.653-1.576-1.556-1.576H9.494zm1.867-16.795a1.554 1.554 0 01-1.348-.782 1.57 1.57 0 01-.148-1.198c.114-.4.388-.744.752-.943l52.175-30.159-10.126-3.097a1.558 1.558 0 01-1.118-1.723 1.562 1.562 0 011.535-1.327h.031c.155.003.309.03.456.08l13.57 4.146a1.56 1.56 0 011.06 1.79l-3.198 13.852a1.567 1.567 0 01-.69.97 1.54 1.54 0 01-1.173.197 1.551 1.551 0 01-1.163-1.866l2.38-10.323L12.165 46.34a1.557 1.557 0 01-.803.225z"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_1_1177">
                    <path
                        fill="#fff"
                        d="M0 0H80.382V75.326H0z"
                        transform="translate(4.81 7.337)"
                    ></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default IncreasesValue;
