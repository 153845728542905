import React from "react";

function SideImage() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 450 331"
        >
            <g clipPath="url(#clip0_1_1201)">
                <path
                    fill="#E9EEF8"
                    d="M20.483 300.256c1.77-15.933-2.973-31.76-7.05-47.201a611.65 611.65 0 01-9.812-43.521c-4.32-23.09-6.889-48.74 4.915-68.644 16.175-27.242 51.76-32.106 74.8-53.022 20.231-18.363 30.206-46.685 51.961-63.21C157.859 7.522 187.116.558 215.026.021c23.069-.443 45.333 5.891 65.619 16.664 16.077 8.538 29.211 21.263 44.404 31.092a127.325 127.325 0 0042.248 17.537c28.202 6.072 54.138 25.347 66.028 51.978 5.865 13.138 7.942 27.798 5.336 41.994-4.95 26.973-23.663 46.215-19.547 75.151 3.949 27.763 18.822 52.635 22.932 80.387.457 3.043.718 6.239.669 9.403H11.945c.495-.656.94-1.281 1.285-1.883 3.833-6.713 6.382-14.279 7.253-22.09"
                ></path>
                <path
                    fill="#fff"
                    d="M161.53 147.164h-39.525v-18.621h39.525v18.621zM204.558 147.164h-39.524v-18.621h39.524v18.621zM247.587 147.164h-39.525v-18.621h39.525v18.621zM139.479 168.797H99.956v-18.621h39.523v18.621zM182.509 168.797h-39.526v-18.621h39.526v18.621zM225.538 168.797h-39.525v-18.621h39.525v18.621zM161.53 190.429h-39.525v-18.62h39.525v18.62zM204.558 190.429h-39.524v-18.62h39.524v18.62zM247.587 190.429h-39.525v-18.62h39.525v18.62zM139.479 212.062H99.956v-18.619h39.523v18.619zM182.509 212.062h-39.526v-18.619h39.526v18.619zM225.538 212.062h-39.525v-18.619h39.525v18.619zM161.53 233.694h-39.525v-18.618h39.525v18.618zM204.558 233.694h-39.524v-18.618h39.524v18.618zM247.587 233.694h-39.525v-18.618h39.525v18.618zM290.613 147.164h-39.526v-18.621h39.526v18.621zM333.642 147.164h-39.525v-18.621h39.525v18.621zM376.67 147.164h-39.524v-18.621h39.524v18.621zM268.564 168.797h-39.526v-18.621h39.526v18.621zM311.591 168.797h-39.523v-18.621h39.523v18.621zM354.621 168.797h-39.526v-18.621h39.526v18.621zM290.613 190.429h-39.526v-18.62h39.526v18.62zM333.642 190.429h-39.525v-18.62h39.525v18.62zM376.67 190.429h-39.524v-18.62h39.524v18.62zM268.564 212.062h-39.526v-18.619h39.526v18.619zM311.591 212.062h-39.523v-18.619h39.523v18.619zM354.621 212.062h-39.526v-18.619h39.526v18.619zM290.613 233.694h-39.526v-18.618h39.526v18.618zM333.642 233.694h-39.525v-18.618h39.525v18.618zM376.67 233.694h-39.524v-18.618h39.524v18.618z"
                ></path>
                <path
                    fill="#5692C4"
                    d="M384.902.604v152.801H270.58V.604h114.322z"
                ></path>
                <path
                    fill="#F0F6F8"
                    d="M377.908 7.174v146.231H277.57V7.175h100.338z"
                ></path>
                <path
                    fill="#E3EDF1"
                    d="M316.288 153.405h-9.1l70.72-66.447v9.9l-61.62 56.547zM277.57 121.039v-38.39l3.874-3.64h32.926l2.065-9.351V46.127l41.45-38.95h20.023v21.77L277.57 121.042m31.297-88.783h-20.865L314.69 7.176h21.51l-27.333 25.082z"
                ></path>
                <path
                    fill="#9AA2E4"
                    d="M378.863 146.725v6.68H276.619v-6.68h102.244z"
                ></path>
                <path
                    fill="#5692C4"
                    d="M331.07 4.578v148.827h-6.658V4.578h6.658z"
                ></path>
                <path
                    fill="#5692C4"
                    d="M274.497 47.543h106.486v6.674H274.497v-6.674zM274.497 97.135h106.486v6.676H274.497v-6.676zM384.902 146.725v6.68H270.58v-6.68h114.322zM116.775 176.905v147.597H36.008V176.905h80.767z"
                ></path>
                <path
                    fill="#DEEEFD"
                    d="M136.699 176.905v147.597H55.932V176.905h80.767z"
                ></path>
                <path
                    fill="#B0C4C9"
                    d="M136.699 225.018V227H55.932v-1.982h80.767zM136.699 274.745v1.98H55.932v-1.98h80.767zM108.135 268.648H84.497v-1.865h23.638v1.865zM108.135 317.873H84.497v-1.865h23.638v1.865z"
                ></path>
                <path
                    fill="#88B9E3"
                    d="M130.75 220.914H60.578V181.2h70.172v39.714z"
                ></path>
                <path
                    fill="#6D8C8B"
                    d="M63.76 163.38s11.323-21.275 15.162-16.665c3.84 4.61-6.767 15.946-15.616 17.843l.454-1.178z"
                ></path>
                <path
                    fill="#8EA8B4"
                    d="M64.622 163.81l.024-.061c.085-.091.198-.209.335-.351.359-.346.815-.869 1.394-1.479a85.274 85.274 0 001.868-2.135 133.43 133.43 0 002.055-2.484c.687-.852 1.363-1.709 1.99-2.516.627-.805 1.216-1.557 1.705-2.207.49-.641.907-1.192 1.194-1.569l.454-.596s-.13.229-.363.627c-.242.397-.577.965-1.022 1.633-.448.666-.96 1.45-1.561 2.268a68.232 68.232 0 01-1.94 2.547 60.02 60.02 0 01-2.12 2.473c-.702.777-1.412 1.479-2.018 2.08a32.322 32.322 0 01-1.554 1.403l-.44.367z"
                ></path>
                <path
                    fill="#6D8C8B"
                    d="M64.18 148.799s4.533-31.606 10.782-29.926c6.25 1.68-11.211 47.257-11.211 47.257l-1.287-6.871 1.96-10.46h-.246"
                ></path>
                <path
                    fill="#8EA8B4"
                    d="M63.73 163.702l.12-1.463c.037-.464.107-1.029.193-1.673.082-.649.174-1.378.316-2.171.261-1.588.589-3.437 1.01-5.412.406-1.975.899-4.077 1.408-6.176.516-2.098 1.06-4.192 1.595-6.151.534-1.961 1.07-3.784 1.525-5.348.454-1.565.871-2.862 1.154-3.772l.462-1.426s-.133.523-.363 1.441c-.232.917-.565 2.229-.965 3.798-.398 1.57-.864 3.406-1.359 5.367-.49 1.965-1.01 4.061-1.53 6.157a341.041 341.041 0 00-1.469 6.163c-.45 1.967-.867 3.807-1.174 5.387-.165.789-.294 1.514-.413 2.156-.12.643-.23 1.203-.287 1.668l-.224 1.455z"
                ></path>
                <path
                    fill="#6D8C8B"
                    d="M61.974 167.123s-10.236-43.248-3.858-44.967c10.476-2.824 6.003 29.087 5.145 44.66l-1.287.307z"
                ></path>
                <path
                    fill="#8EA8B4"
                    d="M62.181 163.958l-.021-.521a.519.519 0 01.033.047l-.012.474zm.02-.806l-.059-.102c-.008-.184-.018-.394-.027-.627-.022-.977-.113-2.373-.178-4.047l-.325-5.715c-.137-2.094-.283-4.323-.427-6.554-.158-2.228-.314-4.459-.463-6.551l-.411-5.715-.29-4.044-.106-1.533.207 1.53c.119.972.304 2.363.481 4.033.174 1.672.394 3.619.578 5.709.195 2.09.364 4.323.526 6.554.146 2.23.279 4.463.365 6.557.091 2.094.123 4.049.158 5.725a122.988 122.988 0 01-.033 4.78"
                ></path>
                <path
                    fill="#6D8C8B"
                    d="M62.288 166.001s-5.391-30.078-12.621-29.238c-7.23.84 12.253 29.697 12.253 29.697l.368-.459z"
                ></path>
                <path
                    fill="#DEE8EE"
                    d="M63.491 165.32l-.128-.18c0-.012 0-.023.002-.035l.126.215z"
                ></path>
                <path
                    fill="#8EA8B4"
                    d="M63.36 165.14l-.512-.721c-.184-.261-.406-.576-.655-.935.002-.096.006-.207.008-.332l.099.172.641 1.064.423.717-.001.035m-1.203-1.703a1.223 1.223 0 00-.04-.055l-.508-.779-.162-.75.048.08c.23.398.447.773.644 1.117l.016.387"
                ></path>
                <path
                    fill="#A8BFCE"
                    d="M62.193 163.484l-.033-.047-.016-.387c.02.033.039.068.058.102l-.007.332"
                ></path>
                <path
                    fill="#8EA8B4"
                    d="M61.61 162.603l-.384-.586c-.328-.5-.663-1.049-1.018-1.627a47.81 47.81 0 01-1.086-1.832 58.573 58.573 0 01-1.1-1.979 75.369 75.369 0 01-1.078-2.057 98.929 98.929 0 01-1.956-4.092 100.481 100.481 0 01-1.519-3.612c-.43-1.06-.737-1.953-.963-2.576l-.328-.984.427.964c.277.61.675 1.491 1.147 2.536.468 1.049 1.06 2.264 1.677 3.568.633 1.301 1.3 2.69 2.012 4.069.345.692.698 1.381 1.059 2.055.349.68.708 1.342 1.056 1.984.344.643.683 1.264 1.013 1.848.306.557.594 1.086.885 1.571l.161.75"
                ></path>
                <path
                    fill="#000"
                    d="M74.317 159.63s-.15 6.901-.273 9.852c-.063 1.475-.443 4.124-1.811 6.405l-.006.01a2.12 2.12 0 01-1.82 1.008H58.6a2.136 2.136 0 01-1.837-1.032c-1.359-2.277-1.743-4.918-1.801-6.391-.123-2.951-.277-9.852-.277-9.852h19.635-.002zM242.067 326.02H224.67v-10.495h17.397v10.495zM338.92 326.02h-17.396v-10.495h17.396v10.495z"
                ></path>
                <path
                    fill="#5F93BF"
                    d="M374.39 228.165v81.668c0 4.803-3.884 8.706-8.69 8.706H223.302c-4.805 0-8.689-3.903-8.689-8.706v-81.668c0-4.803 3.884-8.706 8.689-8.706H365.7c4.806 0 8.69 3.903 8.69 8.706z"
                ></path>
                <path
                    fill="#fff"
                    d="M361.685 228.165v81.668c0 4.803-3.884 8.706-8.689 8.706h-142.4c-4.804 0-8.688-3.903-8.688-8.706v-81.668c0-4.803 3.884-8.706 8.688-8.706h142.4c4.805 0 8.689 3.903 8.689 8.706z"
                ></path>
                <path
                    fill="#5692C4"
                    d="M287.353 269c0 19.81-16.032 35.87-35.81 35.87-19.777 0-35.807-16.06-35.807-35.87s16.032-35.872 35.807-35.872c19.776 0 35.81 16.06 35.81 35.872z"
                ></path>
                <path
                    fill="#5F93BF"
                    d="M373.675 311.624l12.65-5.439v-32.593l-12.65-14.96v52.992z"
                ></path>
                <path
                    fill="#EAF1F3"
                    d="M361.687 311.624l12.651-5.439v-32.593l-12.651-14.96v52.992zM342.165 304.712H311.19a3.107 3.107 0 01-3.105-3.11v-65.206a3.105 3.105 0 013.105-3.108h30.975a3.104 3.104 0 013.102 3.108v65.206a3.106 3.106 0 01-3.102 3.11z"
                ></path>
                <path
                    fill="#fff"
                    d="M268.655 259.232c-3.472 4.777-16.472 10.602-18.11 9.407-1.835-1.336-.103-15.377 3.367-20.156 3.473-4.78 9.588-6.247 13.659-3.28 4.072 2.969 4.557 9.249 1.084 14.029z"
                ></path>
                <path
                    fill="#fff"
                    d="M233.355 257.632c5.867.623 17.403 8.989 17.19 11.007-.24 2.261-13.243 7.779-19.11 7.156-5.868-.623-10.194-5.193-9.664-10.209.53-5.016 5.717-8.577 11.584-7.954z"
                ></path>
                <path
                    fill="#fff"
                    d="M249.623 289.056c-2.397-5.403-.93-19.593.922-20.417 2.073-.922 13.345 7.6 15.741 13.001 2.395 5.402.607 11.44-3.995 13.489-4.602 2.047-10.272-.672-12.668-6.073z"
                ></path>
                <path
                    fill="#DCE0E0"
                    d="M254.244 271.336a4.57 4.57 0 01-6.392 1.01 4.59 4.59 0 01-1.008-6.405 4.572 4.572 0 016.394-1.008 4.588 4.588 0 011.006 6.403z"
                ></path>
                <path
                    fill="#282727"
                    d="M266.31 301.7v-65.4a35.998 35.998 0 0111.085 7.87v49.658a35.988 35.988 0 01-11.085 7.872zm-40.614-7.868v-49.664a36.003 36.003 0 0111.085-7.87v65.404a36.02 36.02 0 01-11.085-7.87zm-2.459-46.814v43.964c-4.714-6.079-7.532-13.704-7.532-21.982 0-8.278 2.818-15.904 7.532-21.982zm15.991-11.738a35.619 35.619 0 0111.081-2.152v71.742a35.534 35.534 0 01-11.081-2.153c0-.017.01-.033.01-.052v-67.332c0-.019-.008-.033-.01-.053zm24.635 67.437a35.611 35.611 0 01-11.095 2.155v-71.744a35.64 35.64 0 0111.095 2.154c0 .018-.01.034-.01.051v67.332c0 .019.01.033.01.052zm15.988-11.737v-43.96c4.715 6.078 7.533 13.704 7.533 21.98s-2.818 15.9-7.533 21.98zm-.288 4.282c6.423-6.874 10.361-16.105 10.361-26.262s-3.938-19.386-10.357-26.26a1.323 1.323 0 00-.16-.166c-6.994-7.399-16.888-12.022-27.864-12.022-10.975 0-20.871 4.625-27.867 12.028a1.074 1.074 0 00-.144.15c-6.427 6.876-10.368 16.109-10.368 26.27 0 10.161 3.943 19.398 10.37 26.272.045.052.087.099.138.144 6.996 7.405 16.892 12.032 27.871 12.032 10.98 0 20.872-4.625 27.868-12.026.056-.049.105-.102.152-.16z"
                ></path>
                <path
                    fill="#fff"
                    d="M340.285 270.981h-27.216v-2.198h27.216v2.198zM340.285 279.245h-27.216v-2.198h27.216v2.198zM340.285 287.509h-27.216v-2.197h27.216v2.197zM340.285 295.773h-27.216v-2.197h27.216v2.197z"
                ></path>
                <path
                    fill="#6D8C8B"
                    d="M316.71 238.308c0 .83-.673 1.504-1.502 1.504a1.503 1.503 0 01-1.501-1.504c0-.83.672-1.504 1.501-1.504.829 0 1.502.674 1.502 1.504z"
                ></path>
                <path
                    fill="#BE5454"
                    d="M323.608 238.308a1.502 1.502 0 11-3.003 0c0-.83.673-1.504 1.502-1.504s1.501.674 1.501 1.504z"
                ></path>
                <path
                    fill="#DCE0E0"
                    d="M292.237 225.799H210.85a2.434 2.434 0 00-2.432 2.436v81.53a2.436 2.436 0 002.432 2.436h81.387a2.434 2.434 0 002.431-2.436v-81.53a2.433 2.433 0 00-2.431-2.436zm0 .68c.967 0 1.753.787 1.753 1.756v81.53c0 .967-.786 1.756-1.753 1.756H210.85a1.757 1.757 0 01-1.751-1.756v-81.53c0-.969.786-1.756 1.751-1.756h81.387z"
                ></path>
                <path
                    fill="#EAF1F3"
                    d="M361.685 309.833c0 4.803-3.884 8.706-8.689 8.706h-142.4c-4.804 0-8.688-3.903-8.688-8.706h159.777z"
                ></path>
                <path
                    fill="#D69F90"
                    d="M151.048 100.832l5.19 17.976s17.627-6.391 23.607-13.065c4.717-5.272 8.817-44.535 8.893-44.92l-8.316 1.23-10.578 34.716-18.796 4.063zM193.876 46.84c-.949.01-3.71 1.027-4.065 2.115-.345 1.086.4 5.461-.369 7.989a7.4 7.4 0 01-.304.838c-.39.89-.731 1.098-.731 1.098l-7.343-.766s.076-.363.113-.998c.055-.739.056-1.838-.16-3.131-.419-2.403-.716-4.788.207-6.694.93-1.908 2.948-5.346 4.625-5.778 1.667-.43 4.385.092 4.945.713.569.621.187 1.131.187 1.131s1.306-.025 1.889.408c.593.442.357.99.357.99s1.525.02 1.849.618c.319.61-.254 1.447-1.2 1.467z"
                ></path>
                <path
                    fill="#BE5454"
                    d="M193.876 46.84c-.949.01-3.71 1.027-4.065 2.115-.345 1.086.4 5.461-.369 7.989a7.4 7.4 0 01-.304.838s.002 2.344-.105 5.745l-9.014-1.1 1.158-5.31c.054-.737.056-1.837-.16-3.13-.419-2.403-.716-4.788.207-6.694.93-1.908 2.948-5.346 4.625-5.778 1.667-.43 4.385.092 4.945.713.569.621.187 1.13.187 1.13s1.306-.024 1.889.41c.593.44.357.99.357.99s1.525.019 1.848.617c.32.61-.253 1.447-1.199 1.467"
                ></path>
                <path
                    fill="#BE5454"
                    d="M183.848 51.102l.369-1.594 1.402-1.524 1.439-.252.881.225.232.559-.569 1.23-.361 2.225-.704 2.688-2.689-3.557z"
                ></path>
                <path
                    fill="#282727"
                    d="M190.985 43.359l-.312.039c-.186.021-.568.068-.815.1-.336.033-.724.1-1.135.168-.41.074-.846.158-1.246.306-.41.125-.702.381-.973.703-.261.31-.48.633-.644.924-.161.293-.302.54-.386.72-.085.179-.136.28-.136.28l.058-.31c.053-.194.117-.477.25-.795.134-.319.298-.696.565-1.053.238-.357.654-.723 1.12-.87a5.803 5.803 0 011.341-.279c.431-.043.833-.039 1.174-.037.343.004.628.04.827.057l.312.043v.004z"
                ></path>
                <path
                    fill="#fff"
                    d="M316.435 69.656H161.55V38.829c0-3.63 2.938-6.575 6.563-6.575h141.76c3.623 0 6.562 2.944 6.562 6.575v30.827z"
                ></path>
                <path
                    fill="#CDD1D1"
                    d="M295.776 37.023a1.382 1.382 0 11-2.761 0 1.382 1.382 0 112.761 0z"
                ></path>
                <path
                    fill="#BE5454"
                    d="M304.02 37.023a1.382 1.382 0 11-2.761 0 1.382 1.382 0 112.761 0z"
                ></path>
                <path
                    fill="#6D8C8B"
                    d="M312.264 37.023a1.382 1.382 0 11-2.761 0 1.382 1.382 0 112.761 0z"
                ></path>
                <path
                    fill="#EAF1F3"
                    d="M316.435 69.656H161.55l2.067 9.35H314.37l2.065-9.35z"
                ></path>
                <path
                    fill="#EAF1F3"
                    d="M174.703 77.645l-1.423-6.446a.417.417 0 01.408-.508h130.613c.267 0 .464.248.407.508l-1.425 6.446a.414.414 0 01-.406.326H175.113a.42.42 0 01-.408-.326"
                ></path>
                <path
                    fill="#CDD1D1"
                    d="M301.267 73.797H176.718v-.877h124.549v.877zM301.267 76.086H176.718v-.877h124.549v.877z"
                ></path>
                <path
                    fill="#B0C4C9"
                    d="M253.431 64.738h-28.878c-.604 0-1.093-.49-1.093-1.096 0-.606.489-1.096 1.093-1.096h28.878a1.095 1.095 0 010 2.192z"
                ></path>
                <path
                    fill="#D69F90"
                    d="M169.922 78.096c.008.498.004.983-.009 1.453-.102 3.434-.716 6.007-1.796 6.526-2.66 1.27-5.563.44-5.563.44l-.813 3.303-.936 3.816-16.835-.523 1.569-11.105s-9.665-19.593-1.382-24.44c8.283-4.849 19.233-3.333 22.841 5.171 1.932 4.56 2.87 10.568 2.926 15.36"
                ></path>
                <path
                    fill="#D69F90"
                    d="M169.4 72.715s1.811 2.338 1.146 3c-.665.662-1.487 1.028-1.487 1.028l.341-4.028z"
                ></path>
                <path
                    fill="#000"
                    d="M166.397 61.116s.029 2.094-4.619 3.354c-4.649 1.26-5.729 1.07-5.729 1.07s1.277 6.977.018 7.64c-.529.277-1.354-.077-2.213-.43-1.19-.488-2.448-.979-3.087.203-1.102 2.035 1.597 3.4 1.579 4.627-.017 1.227.15 3.26-.557 4.07-1.176 1.343-6.25.355-6.25.355s-2.995-3.565-5.095-10.402c-2.1-6.836-3.771-16.743 12.509-18.226 0 0 7.992-4.5 13.446 7.74"
                ></path>
                <path
                    fill="#D69F90"
                    d="M157.141 72.598s-4.656-4.14-6.91-1.323c-2.254 2.817 4.055 7.2 4.055 7.2l2.855-5.877z"
                ></path>
                <mask
                    id="mask0_1_1201"
                    style={{ maskType: "luminance" }}
                    width="5"
                    height="6"
                    x="150"
                    y="71"
                    maskUnits="userSpaceOnUse"
                >
                    <path
                        fill="#fff"
                        d="M153.054 76.121a.207.207 0 01-.138-.052c-.219-.198-2.139-1.966-2.054-3.159.022-.3.17-.55.427-.723a2.475 2.475 0 011.395-.406c.957 0 1.77.46 1.821.488a.209.209 0 01.076.284.21.21 0 01-.283.076c-.012-.006-.76-.428-1.618-.428-.384 0-.79.086-1.16.332a.5.5 0 00-.244.406c-.061.84 1.363 2.32 1.917 2.819a.208.208 0 01-.139.361"
                    ></path>
                </mask>
                <g mask="url(#mask0_1_1201)">
                    <path
                        fill="#BF6B58"
                        d="M154.637 71.781h-3.862v4.34h3.862v-4.34z"
                    ></path>
                </g>
                <path
                    fill="#CE8D7F"
                    d="M161.741 89.82c-2.892-1.942-6.234-6.401-6.234-6.401 3.871 2.295 7.047 3.098 7.047 3.098l-.813 3.303z"
                ></path>
                <path
                    fill="#3A2F44"
                    d="M162.554 86.63l-.027-.004c-.032-.008-3.237-.834-7.077-3.112a.113.113 0 01-.039-.156.111.111 0 01.155-.039c3.81 2.26 6.984 3.078 7.015 3.086a.114.114 0 01-.027.225z"
                ></path>
                <path
                    fill="#BE5454"
                    d="M167.306 58.505l-27.61 11.15a7.512 7.512 0 01-.199-.53c-2.701-7.499.992-15.863 8.435-18.869a14.817 14.817 0 0111.422.121 14.864 14.864 0 017.952 8.126"
                ></path>
                <path
                    fill="#BE5454"
                    d="M145.535 65.445s-1.109 1.765.158 1.646c1.268-.119 16.33-4.346 20.667-5.414 9.453-2.328 10.34-4.006 10.094-5.305-.286-1.526-1.583-2.329-4.529-1.307-2.948 1.022-26.391 10.38-26.391 10.38"
                ></path>
                <path
                    fill="#B54444"
                    d="M139.698 69.656a7.036 7.036 0 01-.199-.531c.881-.332 2.098-.737 3.691-1.213.871-.264 1.7-.54 2.484-.82h.019c.117-.013.351-.057.685-.134l-6.507 2.628-.175.07m15.979-8.247c4.833-4.586 4.153-9.31 3.679-11.032a14.866 14.866 0 017.291 6.706c-3.177 1.24-7.25 2.851-10.97 4.326z"
                ></path>
                <path
                    fill="#B54444"
                    d="M145.674 67.091c4.711-1.691 7.885-3.674 10.001-5.682 3.72-1.477 7.795-3.086 10.97-4.326.241.457.462.931.659 1.422l-20.928 8.453a7.489 7.489 0 01-.685.133h-.019"
                ></path>
                <path
                    fill="#fff"
                    d="M162.224 91.288s7.854 8.058 7.747 16.898c-.107 8.841-5.98 50.116-5.98 50.116l-36.81-1.088s.312-23.248 1.919-43.504c1.607-20.253 15.449-27.223 15.449-27.223s6.715 5.698 17.677 4.8l-.002.001z"
                ></path>
                <path
                    fill="#000"
                    d="M239.179 65.896l-4.085.969 4.686 19.849 4.087-.967-4.688-19.851z"
                ></path>
                <path
                    fill="#000"
                    d="M240.919 82.401a.731.731 0 01-.876-.541l-2.778-11.776a.73.73 0 01.54-.877.73.73 0 01.551.095.753.753 0 01.324.448l2.781 11.776a.734.734 0 01-.542.877M241.252 61.44a5.147 5.147 0 01-4.192 6.269c-2.5.404-4.959-1.122-5.711-3.544a5.139 5.139 0 01-.076-2.822c.064-.246.419-.246.472.004l.007.037c.431 1.994 2.291 3.395 4.321 3.223a4.105 4.105 0 003.666-5.036l-.027-.111c-.067-.244.243-.414.411-.225a5.14 5.14 0 011.129 2.203v.002zM241.683 92.416l-2.137-2.264.897-2.983 3.034-.719 2.135 2.266-.897 2.983-3.032.719v-.002zm5.647-4.108a4.88 4.88 0 00-3.721-3.653 4.716 4.716 0 00-2.152.02 4.741 4.741 0 00-1.934.949 4.89 4.89 0 00-1.693 4.936 4.884 4.884 0 005.873 3.633 4.896 4.896 0 003.627-5.883v-.002z"
                ></path>
                <path
                    fill="#D69F90"
                    d="M251.855 78.782c-.117.63-1.68.586-3.367.543-1.435-.037-2.962-.076-3.763.302-1.741.817-4.179 2.337-4.179 2.337s4.306-.547 5.189-.711c.889-.157 3.646.052 3.751.793.098.748-3.849.613-5.508 1.183-1.659.563-3.58.996-3.991 2.225-.42 1.229-.755 3.55-2.535 4.524-.823.45-1.615.793-2.243 1.041-.68.832-1.674 2.022-2.891 3.467-6.885 8.139-20.959 24.125-27.187 26.395-8.408 3.068-26.969.381-26.969.381l3.881-18.301 18.492 5.286 25.555-21.258 3.828-3.19c.709-.898 2.16-2.8 3.117-4.365 1.301-2.14 2.847-4.35 4.409-4.86 1.564-.502 11.931-2.172 11.617.502 0 0 2.57.375 2.19 2.104 0 0 .829.441.604 1.602z"
                ></path>
                <path
                    fill="#BE5454"
                    d="M251.855 78.782c-.117.63-1.68.586-3.367.543-1.435-.037-2.962-.076-3.763.302-1.741.817-4.179 2.337-4.179 2.337s4.306-.547 5.189-.711c.889-.157 3.646.052 3.751.793.098.748-3.849.613-5.508 1.183-1.659.563-3.58.996-3.991 2.225-.42 1.229-.755 3.55-2.535 4.524-.823.45-1.615.793-2.243 1.041-.68.832-1.674 2.022-2.891 3.467-.488-.27-1.699-1.012-2.999-2.479-1.57-1.767-3.005-4.41-3.229-5.016l3.828-3.19c.709-.898 2.16-2.8 3.117-4.365 1.301-2.14 2.847-4.35 4.409-4.86 1.564-.502 11.931-2.172 11.617.502 0 0 2.57.375 2.19 2.104 0 0 .829.441.604 1.602"
                ></path>
                <path
                    fill="#B04140"
                    d="M238.791 76.785s.127-.109.365-.283c.242-.164.589-.406 1.041-.63.451-.225.989-.475 1.591-.669.601-.199 1.262-.357 1.934-.451a13.469 13.469 0 011.978-.102c.629.01 1.214.075 1.714.13.499.054.908.144 1.195.197l.448.103-.458-.016c-.29-.015-.708-.013-1.203-.027a16.376 16.376 0 00-.448-.006c-.385 0-.806.012-1.244.026a18.73 18.73 0 00-1.919.154c-.645.082-1.285.227-1.88.383a15.624 15.624 0 00-2.704.986c-.263.121-.41.205-.41.205zM240.098 79.096l.405-.295c.266-.174.642-.435 1.123-.697.484-.26 1.051-.56 1.699-.805a7.67 7.67 0 012.115-.51c.441-.035.88-.05 1.307-.05.286 0 .569.007.844.02.683.025 1.316.095 1.86.147.542.055.991.135 1.305.182l.493.09h-.501l-1.312-.012a25.885 25.885 0 00-.693-.007c-.364 0-.754.003-1.158.005-.676.01-1.396.02-2.104.086-.702.051-1.384.225-2.022.434a19.03 19.03 0 00-1.717.654c-.494.221-.909.399-1.194.541l-.45.217z"
                ></path>
                <path
                    fill="#fff"
                    d="M162.312 92.236l23.937 11.63-4.432 18.394-19.407-3.733-.098-26.291z"
                ></path>
                <path
                    fill="#000"
                    d="M184.306 102.598s2.795.92 2.869 1.141c.072.22-1.141 7.77-2.171 12.372-1.027 4.604-1.322 6.629-1.836 6.85-.515.221-2.683-.221-2.941-.516-.257-.295 4.079-19.849 4.079-19.849v.002z"
                ></path>
                <path
                    fill="#D69F90"
                    d="M174.401 308.007l.078 4.354-10.163 2.273-2.805-5.336-.915-5.918-1.261-8.089 8.209-1.727 4.699-.988.251 1.795.168 1.199 1.739 12.437z"
                ></path>
                <path
                    fill="#BE5454"
                    d="M194.235 316.572s1.486 3.448-4.252 4.702c-5.739 1.256-10.165 1.777-13.24 1.468-3.075-.306-4.615-.017-7.739.612-3.124.629-6.286 1.129-8.057-.293-1.451-1.164-.589-3.719-.589-3.719l17.395-1.918 16.482-.852z"
                ></path>
                <path
                    fill="#000"
                    d="M174.635 308.071s7.458 4.13 11.042 5.415c3.586 1.283 7.854-.389 8.558 3.086.704 3.477-8.954 3.805-14.616 3.934-5.662.129-9.845-1.609-12.682-.402-2.837 1.207-6.257.185-6.579-.762-.321-.947.209-9.636.86-10.538 0 0 1.457 2.924 6.992 2.436 5.536-.491 6.425-3.169 6.425-3.169z"
                ></path>
                <path
                    fill="#D69F90"
                    d="M131.602 308.394l-.784 4.281-10.412.215-1.697-5.787.273-5.983.363-8.179 8.388-.064 4.801-.037-.107 1.808-.073 1.21-.752 12.536z"
                ></path>
                <path
                    fill="#BE5454"
                    d="M149.349 320.723s.776 3.674-5.099 3.766c-5.872.092-10.314-.274-13.266-1.186-2.952-.912-4.52-.934-7.706-.937-3.186-.002-6.386-.139-7.839-1.883-1.191-1.43.158-3.764.158-3.764l17.43 1.57 16.324 2.434h-.002z"
                ></path>
                <path
                    fill="#000"
                    d="M131.818 308.505s6.495 5.524 9.753 7.495c3.261 1.971 7.775 1.178 7.778 4.723.004 3.547-9.529 1.955-15.103.957-5.577-.996-9.332-3.532-12.351-2.91-3.018.621-6.169-1.057-6.298-2.051-.128-.993 2.11-9.404 2.925-10.159 0 0 .85 3.156 6.372 3.773 5.524.62 6.924-1.828 6.924-1.828z"
                ></path>
                <path
                    fill="#BE5454"
                    d="M128.004 156.694s-5.762 16.455-5.762 36.507 2.426 33.723 2.426 33.723-6.61 34.034-6.308 75.961l13.587 1.452s9.402-50.678 14.86-74.375c0 0-.8 17.601 12.771 73.767l14.71-2.148s-6.553-71.315-7.162-75.569c-.606-4.254-1.819-48.961-3.638-68.101l-35.484-1.217z"
                ></path>
                <path
                    fill="#3A2F44"
                    d="M146.809 230.075a.113.113 0 01-.109-.082c-.08-.276-8.034-27.893-10.134-48.326a.114.114 0 01.226-.024c2.098 20.414 10.046 48.012 10.126 48.287a.115.115 0 01-.078.141l-.031.004z"
                ></path>
                <path
                    fill="#BE5454"
                    d="M168.961 120.287s-1.846 7.506-11.555 5.467c-8.4-1.764-11.089-14.302-11.089-14.302-7.536-.58-16.378-3.484-16.378-3.484s-.634 1.015-1.449 7.404c-1.004 7.872-1.521 44.322-1.521 44.322l36.815 1.016 5.177-40.423z"
                ></path>
                <path
                    fill="#000"
                    d="M145.301 113.771c.913-3.975 5.413-18.664 22.104-19.306l-4.256-2.25s-15.759.546-21.076 20.903a1.19 1.19 0 001.058 1.495l.932.068a1.177 1.177 0 001.237-.908l.001-.002z"
                ></path>
                <path
                    fill="#fff"
                    d="M144.668 112.794a.788.788 0 11-1.577.001.788.788 0 011.577-.001z"
                ></path>
                <path
                    fill="#000"
                    d="M132.097 110.017c.351-2.446 1.677-10.939 3.711-15.244 0 0-5.659 8.358-6.392 14.947a.707.707 0 00.638.769l1.265.131a.707.707 0 00.776-.603h.002z"
                ></path>
                <path
                    fill="#fff"
                    d="M131.66 109.056a.789.789 0 01-.787.789.79.79 0 010-1.579.79.79 0 01.787.79z"
                ></path>
                <path
                    fill="#3A2F44"
                    d="M168.928 120.205l-.039-.006-12.754-4.647a.112.112 0 01-.068-.144.113.113 0 01.144-.069l12.754 4.647c.058.022.09.086.068.145a.112.112 0 01-.107.074h.002zM139.091 160.357c-5.444 0-12.054-.543-12.134-.549a.114.114 0 01.02-.227c.095.008 9.659.795 15.24.473a.113.113 0 01.119.105.112.112 0 01-.107.119 53.7 53.7 0 01-3.138.079z"
                ></path>
                <path
                    fill="#282727"
                    d="M219.94 218.195h51.295a3.043 3.043 0 003.042-3.047v-29.627h-57.379v29.627a3.044 3.044 0 003.042 3.047z"
                ></path>
                <path
                    fill="#AB4646"
                    d="M219.377 178.397h52.422a2.706 2.706 0 012.704 2.707v10.146h-57.829v-10.146a2.705 2.705 0 012.703-2.707z"
                ></path>
                <path
                    fill="#4E83AE"
                    d="M235.746 218.195h51.295a3.044 3.044 0 003.041-3.047v-29.627h-57.378v29.627a3.043 3.043 0 003.042 3.047z"
                ></path>
                <path
                    fill="#282727"
                    d="M243.048 178.907h27.325v-5.667a3.645 3.645 0 00-3.638-3.644h-20.051a3.644 3.644 0 00-3.636 3.644v5.667zm29.135 1.812h-30.945v-7.479c0-3.01 2.444-5.457 5.446-5.457h20.051c3.004 0 5.448 2.447 5.448 5.457v7.479z"
                ></path>
                <path
                    fill="#BE5454"
                    d="M235.182 178.397h52.422a2.706 2.706 0 012.703 2.707v10.146h-57.829v-10.146a2.706 2.706 0 012.704-2.707z"
                ></path>
                <path
                    fill="#282727"
                    d="M276.223 194.307h2.521a.726.726 0 00.727-.727v-4.573a.726.726 0 00-.727-.726h-2.521c-.4 0-.726.324-.726.726v4.573c0 .402.326.727.726.727zM244.978 194.307h2.521a.726.726 0 00.726-.727v-4.573a.725.725 0 00-.726-.726h-2.521a.726.726 0 00-.727.726v4.573c0 .402.326.727.727.727z"
                ></path>
                <path
                    fill="#5F93BF"
                    d="M450 330.612H7.32v-6.383H450v6.383z"
                ></path>
                <path
                    fill="#fff"
                    d="M399.052 168.623a3.181 3.181 0 11-6.362 0 3.184 3.184 0 013.18-3.188 3.186 3.186 0 013.182 3.188zM224.542 15.517a3.186 3.186 0 01-3.182 3.188 3.185 3.185 0 01-3.181-3.188 3.185 3.185 0 013.181-3.188 3.186 3.186 0 013.182 3.188zM207.931 256.841a1.54 1.54 0 11-3.082 0 1.54 1.54 0 013.082 0zM198.492 87.74a1.54 1.54 0 11-3.079 0 1.54 1.54 0 113.079 0zM36.848 163.702a1.541 1.541 0 11-1.54-1.543c.852 0 1.54.692 1.54 1.543zM112.639 112.81a3.183 3.183 0 11-6.364 0 3.183 3.183 0 116.364 0z"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_1_1201">
                    <path fill="#fff" d="M0 0H450V330.612H0z"></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default SideImage;
