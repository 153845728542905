import React, { ReactElement } from "react";

import SideImage from "./sideImage";

export default function WhatIsHomeWarranty(): ReactElement {
    return (
        <section className="flex flex-col lg:flex-row   lg:mt-40 mt-20 rounded-[20px] bg-[#F2F8FD] lg:px-14 px-5 py-9 relative gap-10 lg:gap-0">
            <div className=" ">
                <h2 className="font-bold lg:text-3xl text-2xl lg:leading-[45px]  ">
                    What Is a Home Warranty?
                </h2>
                <p className="mt-2 lg:max-w-[550px]  lg:text-lg text-base font-light">
                    A home warranty offers coverage for household appliances and
                    systems, providing protection against repair or replacement
                    costs. With various plans available, the coverage and terms
                    can be tailored to your specific needs.
                </p>
            </div>
            <div className="lg:w-[398px] lg:min-w-[398px] lg:h-[276px] w-[300px] h-[200px]  lg:absolute bottom-[37px] right-[37px]  mx-auto lg:mx-0">
                <SideImage />
            </div>
        </section>
    );
}
