import React, { ReactElement } from "react";
import SideImage from "./sideImage";

export default function WhoIsHomeWarranty(): ReactElement {
    return (
        <section className="flex flex-col lg:flex-row lg:gap-28 gap-16 lg:mt-32 mt-24  ">
            <div className="max-w-[574px]  mx-auto lg:mx-0 ">
                <h2 className="font-bold lg:text-3xl text-2xl lg:leading-[45px] text-[#5692C4]">
                    Who Is a Home Warranty For?
                </h2>
                <p className="mt-5   lg:text-lg text-base lg:leading-8 text-[#232323] font-light">
                    A home warranty is a great solution for homeowners looking
                    to protect their appliances and systems from unforeseen
                    breakdowns and costly repairs. It's especially beneficial
                    for individuals who may not have the skills or time to
                    handle maintenance tasks. By providing coverage for home
                    systems, a home warranty offers homeowners peace of mind in
                    the event of unexpected breakdowns.
                </p>
                <span className="w-[122px] h-[8px] block bg-[#F4C2CA] mt-7"></span>
            </div>
            <div className="w-[300px] h-[210px] md:h-[330px] md:w-[450px] md:min-w-[450px] relative mx-auto lg:mx-0 ">
                <SideImage />
            </div>
        </section>
    );
}
